import {IntrospectionQuery} from 'graphql';
export default {
  __schema: {
    queryType: {
      name: 'Query',
    },
    mutationType: {
      name: 'Mutation',
    },
    subscriptionType: null,
    types: [
      {
        kind: 'OBJECT',
        name: 'AddGroupToMitarbeiterPayload',
        fields: [
          {
            name: 'mitarbeiter',
            type: {
              kind: 'INTERFACE',
              name: 'Mitarbeiter',
              ofType: null,
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'OBJECT',
        name: 'AddProzessGroupPayload',
        fields: [
          {
            name: 'prozessGroup',
            type: {
              kind: 'OBJECT',
              name: 'ProzessGroup',
              ofType: null,
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'OBJECT',
        name: 'AddUserGroupPayload',
        fields: [
          {
            name: 'mitarbeiterGruppe',
            type: {
              kind: 'OBJECT',
              name: 'MitarbeiterGruppe',
              ofType: null,
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'OBJECT',
        name: 'AssignedAusfuehrungenCounts',
        fields: [
          {
            name: 'abgeschlossen',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'Int',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'inBearbeitung',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'Int',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'neu',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'Int',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'total',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'Int',
                ofType: null,
              },
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'OBJECT',
        name: 'Ausfuehrung',
        fields: [
          {
            name: 'aktuellerNutzerFolgt',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'Boolean',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'beschreibungstextSlotValues',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'LIST',
                ofType: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'INTERFACE',
                    name: 'SlotValue',
                    ofType: null,
                  },
                },
              },
            },
            args: [],
          },
          {
            name: 'createdAt',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'Instant',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'currentSchritt',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'INTERFACE',
                name: 'Schritt',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'historie',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'LIST',
                ofType: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'OBJECT',
                    name: 'Historie',
                    ofType: null,
                  },
                },
              },
            },
            args: [],
          },
          {
            name: 'id',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'UUID',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'prozessVersion',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'OBJECT',
                name: 'ProzessVersion',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'prozessVersionId',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'UUID',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'sharedKeyEncryptedForBuerger',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'sharedKeyEncryptedForKommune',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'slotValue',
            type: {
              kind: 'OBJECT',
              name: 'PlainSlotValue',
              ofType: null,
            },
            args: [
              {
                name: 'names',
                type: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'LIST',
                    ofType: {
                      kind: 'NON_NULL',
                      ofType: {
                        kind: 'SCALAR',
                        name: 'String',
                        ofType: null,
                      },
                    },
                  },
                },
              },
            ],
          },
          {
            name: 'slotValues',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'LIST',
                ofType: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'INTERFACE',
                    name: 'SlotValue',
                    ofType: null,
                  },
                },
              },
            },
            args: [
              {
                name: 'imagesRaw',
                type: {
                  kind: 'SCALAR',
                  name: 'Boolean',
                  ofType: null,
                },
              },
              {
                name: 'where',
                type: {
                  kind: 'SCALAR',
                  name: 'Any',
                },
              },
            ],
          },
          {
            name: 'status',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'Any',
              },
            },
            args: [],
          },
          {
            name: 'updatedAt',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'Instant',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'user',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'INTERFACE',
                name: 'User',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'userId',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'UUID',
                ofType: null,
              },
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'OBJECT',
        name: 'AusfuehrungContinuePayload',
        fields: [
          {
            name: 'ausfuehrungResult',
            type: {
              kind: 'OBJECT',
              name: 'AusfuehrungResult',
              ofType: null,
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'OBJECT',
        name: 'AusfuehrungCreatePayload',
        fields: [
          {
            name: 'ausfuehrungResult',
            type: {
              kind: 'OBJECT',
              name: 'AusfuehrungResult',
              ofType: null,
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'OBJECT',
        name: 'AusfuehrungFolgenPayload',
        fields: [
          {
            name: 'buerger',
            type: {
              kind: 'OBJECT',
              name: 'Buerger',
              ofType: null,
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'OBJECT',
        name: 'AusfuehrungResult',
        fields: [
          {
            name: 'ausfuehrung',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'OBJECT',
                name: 'Ausfuehrung',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'shouldContinue',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'Boolean',
                ofType: null,
              },
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'OBJECT',
        name: 'BackofficeUser',
        fields: [
          {
            name: 'assignedAusfuehrungen',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'LIST',
                ofType: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'OBJECT',
                    name: 'Ausfuehrung',
                    ofType: null,
                  },
                },
              },
            },
            args: [
              {
                name: 'filter',
                type: {
                  kind: 'SCALAR',
                  name: 'Any',
                },
              },
            ],
          },
          {
            name: 'assignedAusfuehrungenCount',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'OBJECT',
                name: 'AssignedAusfuehrungenCounts',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'ausfuehrungen',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'LIST',
                ofType: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'OBJECT',
                    name: 'Ausfuehrung',
                    ofType: null,
                  },
                },
              },
            },
            args: [],
          },
          {
            name: 'eMail',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'gruppen',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'LIST',
                ofType: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'OBJECT',
                    name: 'MitarbeiterGruppe',
                    ofType: null,
                  },
                },
              },
            },
            args: [],
          },
          {
            name: 'id',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'UUID',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'isKommuneAdmin',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'Boolean',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'kommune',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'OBJECT',
                name: 'Kommune',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'kommuneId',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'UUID',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'maengel',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'LIST',
                ofType: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'OBJECT',
                    name: 'Ausfuehrung',
                    ofType: null,
                  },
                },
              },
            },
            args: [],
          },
          {
            name: 'nachname',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'organisationPrivateKey',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'personalPrivateKey',
            type: {
              kind: 'OBJECT',
              name: 'EncryptedValue',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'personalPrivateKeySalt',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'publicKey',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'untereinheitPermissions',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'LIST',
                ofType: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'OBJECT',
                    name: 'UntereinheitPermissions',
                    ofType: null,
                  },
                },
              },
            },
            args: [],
          },
          {
            name: 'vorname',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null,
              },
            },
            args: [],
          },
        ],
        interfaces: [
          {
            kind: 'INTERFACE',
            name: 'Mitarbeiter',
          },
          {
            kind: 'INTERFACE',
            name: 'User',
          },
        ],
      },
      {
        kind: 'OBJECT',
        name: 'BackofficeUserUpdatePayload',
        fields: [
          {
            name: 'backofficeUser',
            type: {
              kind: 'OBJECT',
              name: 'BackofficeUser',
              ofType: null,
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'OBJECT',
        name: 'Bearbeitung',
        fields: [
          {
            name: 'ausfuehrung',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'OBJECT',
                name: 'Ausfuehrung',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'ausfuehrungId',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'UUID',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'createdAt',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'Instant',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'id',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'UUID',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'nextVerbindung',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'OBJECT',
                name: 'Verbindung',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'nextVerbindungId',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'UUID',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'schritt',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'INTERFACE',
                name: 'Schritt',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'schrittId',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'UUID',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'user',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'INTERFACE',
                name: 'User',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'userId',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'UUID',
                ofType: null,
              },
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'OBJECT',
        name: 'BereichBKommune',
        fields: [
          {
            name: 'analytics',
            type: {
              kind: 'OBJECT',
              name: 'BereichBKommune_Analytics',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'bereichAId',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'createdAt',
            type: {
              kind: 'SCALAR',
              name: 'DateTime',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'defaultPosition',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'LIST',
                ofType: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'SCALAR',
                    name: 'Float',
                    ofType: null,
                  },
                },
              },
            },
            args: [],
          },
          {
            name: 'design',
            type: {
              kind: 'OBJECT',
              name: 'KommuneDesign',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'id',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'lizenzen',
            type: {
              kind: 'OBJECT',
              name: 'BereichBKommune_Lizenzen',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'name',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'regionalschluessel',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'updatedAt',
            type: {
              kind: 'SCALAR',
              name: 'DateTime',
              ofType: null,
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'OBJECT',
        name: 'BereichBKommune_Analytics',
        fields: [
          {
            name: 'siteId',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'url',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'OBJECT',
        name: 'BereichBKommune_Lizenzen',
        fields: [
          {
            name: 'kartenlizenzen',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'JSON',
                ofType: null,
              },
            },
            args: [
              {
                name: 'depth',
                type: {
                  kind: 'SCALAR',
                  name: 'Int',
                  ofType: null,
                },
              },
            ],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'OBJECT',
        name: 'BereichBUntereinheit',
        fields: [
          {
            name: 'createdAt',
            type: {
              kind: 'SCALAR',
              name: 'DateTime',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'id',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'isGemeinde',
            type: {
              kind: 'SCALAR',
              name: 'Boolean',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'kommune',
            type: {
              kind: 'OBJECT',
              name: 'BereichBKommune',
              ofType: null,
            },
            args: [
              {
                name: 'fallbackLocale',
                type: {
                  kind: 'SCALAR',
                  name: 'Any',
                },
              },
              {
                name: 'locale',
                type: {
                  kind: 'SCALAR',
                  name: 'Any',
                },
              },
            ],
          },
          {
            name: 'name',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'regionalschluessel',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'updatedAt',
            type: {
              kind: 'SCALAR',
              name: 'DateTime',
              ofType: null,
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'OBJECT',
        name: 'BezahlenSchritt',
        fields: [
          {
            name: 'anweisung',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'betrag',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'Decimal',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'erledigungDurchBuerger',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'Boolean',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'id',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'UUID',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'informationstext',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'mitarbeiterGruppe',
            type: {
              kind: 'OBJECT',
              name: 'MitarbeiterGruppe',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'mitarbeiterGruppeId',
            type: {
              kind: 'SCALAR',
              name: 'UUID',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'prozessVersion',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'OBJECT',
                name: 'ProzessVersion',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'prozessVersionId',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'UUID',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'slots',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'LIST',
                ofType: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'OBJECT',
                    name: 'Slot',
                    ofType: null,
                  },
                },
              },
            },
            args: [],
          },
          {
            name: 'titel',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'verbindungen',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'LIST',
                ofType: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'OBJECT',
                    name: 'Verbindung',
                    ofType: null,
                  },
                },
              },
            },
            args: [],
          },
        ],
        interfaces: [
          {
            kind: 'INTERFACE',
            name: 'Schritt',
          },
        ],
      },
      {
        kind: 'OBJECT',
        name: 'BezahlenSchrittUrlPayload',
        fields: [
          {
            name: 'string',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'SCALAR',
        name: 'BoolExpression',
      },
      {
        kind: 'SCALAR',
        name: 'Boolean',
      },
      {
        kind: 'OBJECT',
        name: 'Buerger',
        fields: [
          {
            name: 'ausfuehrungen',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'LIST',
                ofType: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'OBJECT',
                    name: 'Ausfuehrung',
                    ofType: null,
                  },
                },
              },
            },
            args: [],
          },
          {
            name: 'gefolgteAusfuehrungen',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'LIST',
                ofType: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'OBJECT',
                    name: 'Ausfuehrung',
                    ofType: null,
                  },
                },
              },
            },
            args: [],
          },
          {
            name: 'id',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'UUID',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'maengel',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'LIST',
                ofType: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'OBJECT',
                    name: 'Ausfuehrung',
                    ofType: null,
                  },
                },
              },
            },
            args: [],
          },
          {
            name: 'mitteilungen',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'LIST',
                ofType: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'OBJECT',
                    name: 'Ausfuehrung',
                    ofType: null,
                  },
                },
              },
            },
            args: [],
          },
          {
            name: 'personalData',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'LIST',
                ofType: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'OBJECT',
                    name: 'BuergerPersonalData',
                    ofType: null,
                  },
                },
              },
            },
            args: [],
          },
          {
            name: 'personalDataKey',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'personalPrivateKey',
            type: {
              kind: 'OBJECT',
              name: 'EncryptedValue',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'personalPrivateKeySalt',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'publicKey',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'pushNotificationTokens',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'LIST',
                ofType: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'SCALAR',
                    name: 'String',
                    ofType: null,
                  },
                },
              },
            },
            args: [],
          },
        ],
        interfaces: [
          {
            kind: 'INTERFACE',
            name: 'User',
          },
        ],
      },
      {
        kind: 'OBJECT',
        name: 'BuergerPersonalData',
        fields: [
          {
            name: 'buergerId',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'UUID',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'id',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'UUID',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'key',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'value',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'OBJECT',
                name: 'EncryptedValue',
                ofType: null,
              },
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'OBJECT',
        name: 'CheckboxListFeldOption',
        fields: [
          {
            name: 'defaultChecked',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'Boolean',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'key',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'label',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'order',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'Int',
                ofType: null,
              },
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'OBJECT',
        name: 'CheckboxListFormularFeld',
        fields: [
          {
            name: 'angezeigterName',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'anweisung',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'encrypted',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'Boolean',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'id',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'UUID',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'inhaltsTag',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'istRelevant',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'BoolExpression',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'options',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'LIST',
                ofType: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'OBJECT',
                    name: 'CheckboxListFeldOption',
                    ofType: null,
                  },
                },
              },
            },
            args: [],
          },
          {
            name: 'order',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'Int',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'schritt',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'OBJECT',
                name: 'FormularSchritt',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'schrittId',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'UUID',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'slotName',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'slotValueType',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'Any',
              },
            },
            args: [],
          },
        ],
        interfaces: [
          {
            kind: 'INTERFACE',
            name: 'FormularFeld',
          },
        ],
      },
      {
        kind: 'OBJECT',
        name: 'DateFormularFeld',
        fields: [
          {
            name: 'angezeigterName',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'anweisung',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'encrypted',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'Boolean',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'id',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'UUID',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'inhaltsTag',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'istRelevant',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'BoolExpression',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'order',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'Int',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'schritt',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'OBJECT',
                name: 'FormularSchritt',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'schrittId',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'UUID',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'slotName',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'slotValueType',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'Any',
              },
            },
            args: [],
          },
        ],
        interfaces: [
          {
            kind: 'INTERFACE',
            name: 'FormularFeld',
          },
        ],
      },
      {
        kind: 'SCALAR',
        name: 'DateTime',
      },
      {
        kind: 'SCALAR',
        name: 'Decimal',
      },
      {
        kind: 'UNION',
        name: 'DeleteProzessGroupError',
        possibleTypes: [
          {
            kind: 'OBJECT',
            name: 'ProzessGroupAssignedToProzess',
          },
          {
            kind: 'OBJECT',
            name: 'ProzessGroupNotFound',
          },
        ],
      },
      {
        kind: 'OBJECT',
        name: 'DeleteProzessGroupPayload',
        fields: [
          {
            name: 'errors',
            type: {
              kind: 'LIST',
              ofType: {
                kind: 'NON_NULL',
                ofType: {
                  kind: 'UNION',
                  name: 'DeleteProzessGroupError',
                  ofType: null,
                },
              },
            },
            args: [],
          },
          {
            name: 'prozessGroup',
            type: {
              kind: 'OBJECT',
              name: 'ProzessGroup',
              ofType: null,
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'UNION',
        name: 'DeleteUserGroupError',
        possibleTypes: [
          {
            kind: 'OBJECT',
            name: 'GroupAssignedToSchritt',
          },
          {
            kind: 'OBJECT',
            name: 'GroupNotFound',
          },
        ],
      },
      {
        kind: 'OBJECT',
        name: 'DeleteUserGroupPayload',
        fields: [
          {
            name: 'errors',
            type: {
              kind: 'LIST',
              ofType: {
                kind: 'NON_NULL',
                ofType: {
                  kind: 'UNION',
                  name: 'DeleteUserGroupError',
                  ofType: null,
                },
              },
            },
            args: [],
          },
          {
            name: 'mitarbeiterGruppe',
            type: {
              kind: 'OBJECT',
              name: 'MitarbeiterGruppe',
              ofType: null,
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'OBJECT',
        name: 'EfaLeistung',
        fields: [
          {
            name: 'group',
            type: {
              kind: 'OBJECT',
              name: 'ProzessGroup',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'groupId',
            type: {
              kind: 'SCALAR',
              name: 'UUID',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'htmlSnippet',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'id',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'UUID',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'kommune',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'OBJECT',
                name: 'Kommune',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'kommuneId',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'UUID',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'name',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null,
              },
            },
            args: [],
          },
        ],
        interfaces: [
          {
            kind: 'INTERFACE',
            name: 'UserFacingProzess',
          },
        ],
      },
      {
        kind: 'OBJECT',
        name: 'EmailForwarderExportSchritt',
        fields: [
          {
            name: 'anweisung',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'body',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'StringExpression',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'emailAddress',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'StringExpression',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'erledigungDurchBuerger',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'Boolean',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'id',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'UUID',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'informationstext',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'mitarbeiterGruppe',
            type: {
              kind: 'OBJECT',
              name: 'MitarbeiterGruppe',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'mitarbeiterGruppeId',
            type: {
              kind: 'SCALAR',
              name: 'UUID',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'prozessVersion',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'OBJECT',
                name: 'ProzessVersion',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'prozessVersionId',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'UUID',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'slots',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'LIST',
                ofType: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'OBJECT',
                    name: 'Slot',
                    ofType: null,
                  },
                },
              },
            },
            args: [],
          },
          {
            name: 'subject',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'StringExpression',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'titel',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'verbindungen',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'LIST',
                ofType: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'OBJECT',
                    name: 'Verbindung',
                    ofType: null,
                  },
                },
              },
            },
            args: [],
          },
        ],
        interfaces: [
          {
            kind: 'INTERFACE',
            name: 'Schritt',
          },
        ],
      },
      {
        kind: 'OBJECT',
        name: 'EncryptedSlotValue',
        fields: [
          {
            name: 'bearbeitung',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'OBJECT',
                name: 'Bearbeitung',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'id',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'UUID',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'slotName',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'value',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'OBJECT',
                name: 'EncryptedValue',
                ofType: null,
              },
            },
            args: [],
          },
        ],
        interfaces: [
          {
            kind: 'INTERFACE',
            name: 'SlotValue',
          },
        ],
      },
      {
        kind: 'OBJECT',
        name: 'EncryptedValue',
        fields: [
          {
            name: 'cipher',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'nonce',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'tag',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null,
              },
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'OBJECT',
        name: 'EndeSchritt',
        fields: [
          {
            name: 'anweisung',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'erledigungDurchBuerger',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'Boolean',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'id',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'UUID',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'informationstext',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'mitarbeiterGruppe',
            type: {
              kind: 'OBJECT',
              name: 'MitarbeiterGruppe',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'mitarbeiterGruppeId',
            type: {
              kind: 'SCALAR',
              name: 'UUID',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'prozessVersion',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'OBJECT',
                name: 'ProzessVersion',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'prozessVersionId',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'UUID',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'slots',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'LIST',
                ofType: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'OBJECT',
                    name: 'Slot',
                    ofType: null,
                  },
                },
              },
            },
            args: [],
          },
          {
            name: 'titel',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'verbindungen',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'LIST',
                ofType: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'OBJECT',
                    name: 'Verbindung',
                    ofType: null,
                  },
                },
              },
            },
            args: [],
          },
        ],
        interfaces: [
          {
            kind: 'INTERFACE',
            name: 'Schritt',
          },
        ],
      },
      {
        kind: 'INTERFACE',
        name: 'Error',
        fields: [
          {
            name: 'message',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null,
              },
            },
            args: [],
          },
        ],
        interfaces: [],
        possibleTypes: [
          {
            kind: 'OBJECT',
            name: 'GroupAssignedToSchritt',
          },
          {
            kind: 'OBJECT',
            name: 'GroupNameInvalid',
          },
          {
            kind: 'OBJECT',
            name: 'GroupNotFound',
          },
          {
            kind: 'OBJECT',
            name: 'ProzessGroupAssignedToProzess',
          },
          {
            kind: 'OBJECT',
            name: 'ProzessGroupNotFound',
          },
          {
            kind: 'OBJECT',
            name: 'UntereinheitAssignedToUser',
          },
          {
            kind: 'OBJECT',
            name: 'UntereinheitIsGemeindeError',
          },
          {
            kind: 'OBJECT',
            name: 'UntereinheitNotFoundError',
          },
        ],
      },
      {
        kind: 'OBJECT',
        name: 'FilePart',
        fields: [
          {
            name: 'slotName',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null,
              },
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'SCALAR',
        name: 'Float',
      },
      {
        kind: 'INTERFACE',
        name: 'FormularFeld',
        fields: [
          {
            name: 'angezeigterName',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'anweisung',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'encrypted',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'Boolean',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'id',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'UUID',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'inhaltsTag',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'istRelevant',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'BoolExpression',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'order',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'Int',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'schritt',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'OBJECT',
                name: 'FormularSchritt',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'schrittId',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'UUID',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'slotName',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'slotValueType',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'Any',
              },
            },
            args: [],
          },
        ],
        interfaces: [],
        possibleTypes: [
          {
            kind: 'OBJECT',
            name: 'CheckboxListFormularFeld',
          },
          {
            kind: 'OBJECT',
            name: 'DateFormularFeld',
          },
          {
            kind: 'OBJECT',
            name: 'LocationFormularFeld',
          },
          {
            kind: 'OBJECT',
            name: 'SignatureFormularFeld',
          },
          {
            kind: 'OBJECT',
            name: 'StringFormularFeld',
          },
          {
            kind: 'OBJECT',
            name: 'TimeFormularFeld',
          },
          {
            kind: 'OBJECT',
            name: 'UploadFormularFeld',
          },
        ],
      },
      {
        kind: 'OBJECT',
        name: 'FormularSchritt',
        fields: [
          {
            name: 'anweisung',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'erledigungDurchBuerger',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'Boolean',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'felder',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'LIST',
                ofType: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'INTERFACE',
                    name: 'FormularFeld',
                    ofType: null,
                  },
                },
              },
            },
            args: [],
          },
          {
            name: 'id',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'UUID',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'informationstext',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'mitarbeiterGruppe',
            type: {
              kind: 'OBJECT',
              name: 'MitarbeiterGruppe',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'mitarbeiterGruppeId',
            type: {
              kind: 'SCALAR',
              name: 'UUID',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'prozessVersion',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'OBJECT',
                name: 'ProzessVersion',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'prozessVersionId',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'UUID',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'slots',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'LIST',
                ofType: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'OBJECT',
                    name: 'Slot',
                    ofType: null,
                  },
                },
              },
            },
            args: [],
          },
          {
            name: 'titel',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'verbindungen',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'LIST',
                ofType: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'OBJECT',
                    name: 'Verbindung',
                    ofType: null,
                  },
                },
              },
            },
            args: [],
          },
        ],
        interfaces: [
          {
            kind: 'INTERFACE',
            name: 'Schritt',
          },
        ],
      },
      {
        kind: 'OBJECT',
        name: 'Fraktion',
        fields: [
          {
            name: 'col',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'id',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'name',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null,
              },
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'OBJECT',
        name: 'GeoFlaechen',
        fields: [
          {
            name: 'Geometry',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'JSON',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'createdAt',
            type: {
              kind: 'SCALAR',
              name: 'DateTime',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'id',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'kommune',
            type: {
              kind: 'OBJECT',
              name: 'BereichBKommune',
              ofType: null,
            },
            args: [
              {
                name: 'fallbackLocale',
                type: {
                  kind: 'SCALAR',
                  name: 'Any',
                },
              },
              {
                name: 'locale',
                type: {
                  kind: 'SCALAR',
                  name: 'Any',
                },
              },
            ],
          },
          {
            name: 'name',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'updatedAt',
            type: {
              kind: 'SCALAR',
              name: 'DateTime',
              ofType: null,
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'OBJECT',
        name: 'GovManagerRestExportSchritt',
        fields: [
          {
            name: 'anweisung',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'erledigungDurchBuerger',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'Boolean',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'exportFilePartsAsPdf',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'Boolean',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'exportParts',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'LIST',
                ofType: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'UNION',
                    name: 'IPart',
                    ofType: null,
                  },
                },
              },
            },
            args: [],
          },
          {
            name: 'id',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'UUID',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'informationstext',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'mandantenSchluessel',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'mitarbeiterGruppe',
            type: {
              kind: 'OBJECT',
              name: 'MitarbeiterGruppe',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'mitarbeiterGruppeId',
            type: {
              kind: 'SCALAR',
              name: 'UUID',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'prozessVersion',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'OBJECT',
                name: 'ProzessVersion',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'prozessVersionId',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'UUID',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'slots',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'LIST',
                ofType: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'OBJECT',
                    name: 'Slot',
                    ofType: null,
                  },
                },
              },
            },
            args: [],
          },
          {
            name: 'titel',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'verbindungen',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'LIST',
                ofType: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'OBJECT',
                    name: 'Verbindung',
                    ofType: null,
                  },
                },
              },
            },
            args: [],
          },
          {
            name: 'vorgangsSchluessel',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'vorgangsTyp',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null,
              },
            },
            args: [],
          },
        ],
        interfaces: [
          {
            kind: 'INTERFACE',
            name: 'Schritt',
          },
        ],
      },
      {
        kind: 'OBJECT',
        name: 'GroupAssignedToSchritt',
        fields: [
          {
            name: 'message',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null,
              },
            },
            args: [],
          },
        ],
        interfaces: [
          {
            kind: 'INTERFACE',
            name: 'Error',
          },
        ],
      },
      {
        kind: 'OBJECT',
        name: 'GroupNameInvalid',
        fields: [
          {
            name: 'message',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null,
              },
            },
            args: [],
          },
        ],
        interfaces: [
          {
            kind: 'INTERFACE',
            name: 'Error',
          },
        ],
      },
      {
        kind: 'OBJECT',
        name: 'GroupNotFound',
        fields: [
          {
            name: 'message',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null,
              },
            },
            args: [],
          },
        ],
        interfaces: [
          {
            kind: 'INTERFACE',
            name: 'Error',
          },
        ],
      },
      {
        kind: 'OBJECT',
        name: 'Historie',
        fields: [
          {
            name: 'createdAt',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'Instant',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'verbindung',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'OBJECT',
                name: 'Verbindung',
                ofType: null,
              },
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'SCALAR',
        name: 'ID',
      },
      {
        kind: 'UNION',
        name: 'IJsonValueWithSlots',
        possibleTypes: [
          {
            kind: 'OBJECT',
            name: 'JsonObject',
          },
          {
            kind: 'OBJECT',
            name: 'JsonSlotValue',
          },
          {
            kind: 'OBJECT',
            name: 'JsonStringValue',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'IPart',
        possibleTypes: [
          {
            kind: 'OBJECT',
            name: 'FilePart',
          },
          {
            kind: 'OBJECT',
            name: 'JsonPart',
          },
        ],
      },
      {
        kind: 'OBJECT',
        name: 'ImageBlockMedien',
        fields: [
          {
            name: 'blockName',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'blockType',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'id',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'image',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'OBJECT',
                name: 'Medien',
                ofType: null,
              },
            },
            args: [
              {
                name: 'fallbackLocale',
                type: {
                  kind: 'SCALAR',
                  name: 'Any',
                },
              },
              {
                name: 'locale',
                type: {
                  kind: 'SCALAR',
                  name: 'Any',
                },
              },
              {
                name: 'where',
                type: {
                  kind: 'SCALAR',
                  name: 'Any',
                },
              },
            ],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'OBJECT',
        name: 'ImageBlockVeranstaltungsmedien',
        fields: [
          {
            name: 'blockName',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'blockType',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'id',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'image',
            type: {
              kind: 'OBJECT',
              name: 'Veranstaltungsmedien',
              ofType: null,
            },
            args: [
              {
                name: 'fallbackLocale',
                type: {
                  kind: 'SCALAR',
                  name: 'Any',
                },
              },
              {
                name: 'locale',
                type: {
                  kind: 'SCALAR',
                  name: 'Any',
                },
              },
              {
                name: 'where',
                type: {
                  kind: 'SCALAR',
                  name: 'Any',
                },
              },
            ],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'SCALAR',
        name: 'Instant',
      },
      {
        kind: 'SCALAR',
        name: 'Int',
      },
      {
        kind: 'OBJECT',
        name: 'Integration',
        fields: [
          {
            name: 'createdAt',
            type: {
              kind: 'SCALAR',
              name: 'DateTime',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'id',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'integrationtype',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'Any',
              },
            },
            args: [],
          },
          {
            name: 'kommune',
            type: {
              kind: 'OBJECT',
              name: 'BereichBKommune',
              ofType: null,
            },
            args: [
              {
                name: 'fallbackLocale',
                type: {
                  kind: 'SCALAR',
                  name: 'Any',
                },
              },
              {
                name: 'locale',
                type: {
                  kind: 'SCALAR',
                  name: 'Any',
                },
              },
            ],
          },
          {
            name: 'konfiguration',
            type: {
              kind: 'OBJECT',
              name: 'Integration_Konfiguration',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'status',
            type: {
              kind: 'OBJECT',
              name: 'Integration_Status',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'untereinheit',
            type: {
              kind: 'OBJECT',
              name: 'BereichBUntereinheit',
              ofType: null,
            },
            args: [
              {
                name: 'fallbackLocale',
                type: {
                  kind: 'SCALAR',
                  name: 'Any',
                },
              },
              {
                name: 'locale',
                type: {
                  kind: 'SCALAR',
                  name: 'Any',
                },
              },
            ],
          },
          {
            name: 'updatedAt',
            type: {
              kind: 'SCALAR',
              name: 'DateTime',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'url',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'zugangsdaten',
            type: {
              kind: 'OBJECT',
              name: 'Integration_Zugangsdaten',
              ofType: null,
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'OBJECT',
        name: 'Integration_Konfiguration',
        fields: [
          {
            name: 'content',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'kategorien',
            type: {
              kind: 'LIST',
              ofType: {
                kind: 'NON_NULL',
                ofType: {
                  kind: 'OBJECT',
                  name: 'Integration_Konfiguration_Kategorien',
                  ofType: null,
                },
              },
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'OBJECT',
        name: 'Integration_Konfiguration_Kategorien',
        fields: [
          {
            name: 'gewichtung',
            type: {
              kind: 'SCALAR',
              name: 'Float',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'id',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'kategorie',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'OBJECT',
        name: 'Integration_Status',
        fields: [
          {
            name: 'erfolg',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'import_log',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'last_import_date',
            type: {
              kind: 'SCALAR',
              name: 'DateTime',
              ofType: null,
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'OBJECT',
        name: 'Integration_Zugangsdaten',
        fields: [
          {
            name: 'benutzername',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'passwort',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'token',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'OBJECT',
        name: 'IsInside',
        fields: [
          {
            name: 'isInside',
            type: {
              kind: 'SCALAR',
              name: 'Boolean',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'message',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'SCALAR',
        name: 'JSON',
      },
      {
        kind: 'OBJECT',
        name: 'JsonField',
        fields: [
          {
            name: 'name',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'value',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'UNION',
                name: 'IJsonValueWithSlots',
                ofType: null,
              },
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'OBJECT',
        name: 'JsonObject',
        fields: [
          {
            name: 'fields',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'LIST',
                ofType: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'OBJECT',
                    name: 'JsonField',
                    ofType: null,
                  },
                },
              },
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'OBJECT',
        name: 'JsonPart',
        fields: [
          {
            name: 'value',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'UNION',
                name: 'IJsonValueWithSlots',
                ofType: null,
              },
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'OBJECT',
        name: 'JsonSlotValue',
        fields: [
          {
            name: 'slotName',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null,
              },
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'OBJECT',
        name: 'JsonStringValue',
        fields: [
          {
            name: 'value',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null,
              },
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'OBJECT',
        name: 'Kommune',
        fields: [
          {
            name: 'abfallkalender',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'LIST',
                ofType: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'OBJECT',
                    name: 'Termin',
                    ofType: null,
                  },
                },
              },
            },
            args: [
              {
                name: 'bis',
                type: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'SCALAR',
                    name: 'DateTime',
                    ofType: null,
                  },
                },
              },
              {
                name: 'standortId',
                type: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'SCALAR',
                    name: 'String',
                    ofType: null,
                  },
                },
              },
              {
                name: 'von',
                type: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'SCALAR',
                    name: 'DateTime',
                    ofType: null,
                  },
                },
              },
              {
                name: 'where',
                type: {
                  kind: 'SCALAR',
                  name: 'Any',
                },
              },
            ],
          },
          {
            name: 'abfallkalenderEnabled',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'Boolean',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'analytics',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'OBJECT',
                name: 'BereichBKommune_Analytics',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'ausfuehrungen',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'LIST',
                ofType: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'OBJECT',
                    name: 'Ausfuehrung',
                    ofType: null,
                  },
                },
              },
            },
            args: [],
          },
          {
            name: 'bereichBId',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'ID',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'defaultPosition',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'LIST',
                ofType: {
                  kind: 'SCALAR',
                  name: 'Float',
                  ofType: null,
                },
              },
            },
            args: [],
          },
          {
            name: 'design',
            type: {
              kind: 'OBJECT',
              name: 'KommuneDesign',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'fraktionen',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'LIST',
                ofType: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'OBJECT',
                    name: 'Fraktion',
                    ofType: null,
                  },
                },
              },
            },
            args: [
              {
                name: 'standortId',
                type: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'SCALAR',
                    name: 'String',
                    ofType: null,
                  },
                },
              },
            ],
          },
          {
            name: 'geoFlaechen',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'OBJECT',
                name: 'GeoFlaechen',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'id',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'UUID',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'isPointInGeoflaeche',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'OBJECT',
                name: 'IsInside',
                ofType: null,
              },
            },
            args: [
              {
                name: 'name',
                type: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'SCALAR',
                    name: 'String',
                    ofType: null,
                  },
                },
              },
              {
                name: 'position',
                type: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'SCALAR',
                    name: 'Any',
                  },
                },
              },
            ],
          },
          {
            name: 'lizenzen',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'OBJECT',
                name: 'BereichBKommune_Lizenzen',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'maengelAusfuehrungen',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'LIST',
                ofType: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'OBJECT',
                    name: 'Ausfuehrung',
                    ofType: null,
                  },
                },
              },
            },
            args: [],
          },
          {
            name: 'mangelAusfuehrung',
            type: {
              kind: 'OBJECT',
              name: 'Ausfuehrung',
              ofType: null,
            },
            args: [
              {
                name: 'id',
                type: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'SCALAR',
                    name: 'UUID',
                    ofType: null,
                  },
                },
              },
            ],
          },
          {
            name: 'mitarbeiter',
            type: {
              kind: 'OBJECT',
              name: 'MitarbeiterConnection',
              ofType: null,
            },
            args: [
              {
                name: 'after',
                type: {
                  kind: 'SCALAR',
                  name: 'String',
                  ofType: null,
                },
              },
              {
                name: 'before',
                type: {
                  kind: 'SCALAR',
                  name: 'String',
                  ofType: null,
                },
              },
              {
                name: 'first',
                type: {
                  kind: 'SCALAR',
                  name: 'Int',
                  ofType: null,
                },
              },
              {
                name: 'groupId',
                type: {
                  kind: 'SCALAR',
                  name: 'UUID',
                  ofType: null,
                },
              },
              {
                name: 'last',
                type: {
                  kind: 'SCALAR',
                  name: 'Int',
                  ofType: null,
                },
              },
              {
                name: 'search',
                type: {
                  kind: 'SCALAR',
                  name: 'String',
                  ofType: null,
                },
              },
            ],
          },
          {
            name: 'mitarbeiterGruppen',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'LIST',
                ofType: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'OBJECT',
                    name: 'MitarbeiterGruppe',
                    ofType: null,
                  },
                },
              },
            },
            args: [],
          },
          {
            name: 'name',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'orte',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'LIST',
                ofType: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'OBJECT',
                    name: 'Ort',
                    ofType: null,
                  },
                },
              },
            },
            args: [],
          },
          {
            name: 'privateKey',
            type: {
              kind: 'OBJECT',
              name: 'EncryptedValue',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'prozessById',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'OBJECT',
                name: 'Prozess',
                ofType: null,
              },
            },
            args: [
              {
                name: 'id',
                type: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'SCALAR',
                    name: 'UUID',
                    ofType: null,
                  },
                },
              },
            ],
          },
          {
            name: 'prozessByTag',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'OBJECT',
                name: 'Prozess',
                ofType: null,
              },
            },
            args: [
              {
                name: 'tag',
                type: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'SCALAR',
                    name: 'String',
                    ofType: null,
                  },
                },
              },
            ],
          },
          {
            name: 'prozessGroups',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'LIST',
                ofType: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'OBJECT',
                    name: 'ProzessGroup',
                    ofType: null,
                  },
                },
              },
            },
            args: [],
          },
          {
            name: 'prozesse',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'LIST',
                ofType: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'OBJECT',
                    name: 'Prozess',
                    ofType: null,
                  },
                },
              },
            },
            args: [
              {
                name: 'groupId',
                type: {
                  kind: 'SCALAR',
                  name: 'UUID',
                  ofType: null,
                },
              },
              {
                name: 'search',
                type: {
                  kind: 'SCALAR',
                  name: 'String',
                  ofType: null,
                },
              },
            ],
          },
          {
            name: 'prozesseUndLeistungen',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'LIST',
                ofType: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'INTERFACE',
                    name: 'UserFacingProzess',
                    ofType: null,
                  },
                },
              },
            },
            args: [
              {
                name: 'groupId',
                type: {
                  kind: 'SCALAR',
                  name: 'UUID',
                  ofType: null,
                },
              },
              {
                name: 'search',
                type: {
                  kind: 'SCALAR',
                  name: 'String',
                  ofType: null,
                },
              },
            ],
          },
          {
            name: 'publicKey',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'servicePortalLink',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'OBJECT',
                name: 'ServicePortalLink',
                ofType: null,
              },
            },
            args: [
              {
                name: 'id',
                type: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'SCALAR',
                    name: 'UUID',
                    ofType: null,
                  },
                },
              },
            ],
          },
          {
            name: 'standorte',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'LIST',
                ofType: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'OBJECT',
                    name: 'Standort',
                    ofType: null,
                  },
                },
              },
            },
            args: [
              {
                name: 'strassenId',
                type: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'SCALAR',
                    name: 'String',
                    ofType: null,
                  },
                },
              },
            ],
          },
          {
            name: 'strassen',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'LIST',
                ofType: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'OBJECT',
                    name: 'Strasse',
                    ofType: null,
                  },
                },
              },
            },
            args: [
              {
                name: 'ortId',
                type: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'SCALAR',
                    name: 'String',
                    ofType: null,
                  },
                },
              },
            ],
          },
          {
            name: 'untereinheiten',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'LIST',
                ofType: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'OBJECT',
                    name: 'Untereinheit',
                    ofType: null,
                  },
                },
              },
            },
            args: [
              {
                name: 'where',
                type: {
                  kind: 'SCALAR',
                  name: 'Any',
                },
              },
            ],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'OBJECT',
        name: 'KommuneDesign',
        fields: [
          {
            name: 'colors',
            type: {
              kind: 'OBJECT',
              name: 'KommuneDesignColors',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'fonts',
            type: {
              kind: 'OBJECT',
              name: 'KommuneDesignFonts',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'logo',
            type: {
              kind: 'OBJECT',
              name: 'Medien',
              ofType: null,
            },
            args: [
              {
                name: 'fallbackLocale',
                type: {
                  kind: 'SCALAR',
                  name: 'Any',
                },
              },
              {
                name: 'locale',
                type: {
                  kind: 'SCALAR',
                  name: 'Any',
                },
              },
              {
                name: 'where',
                type: {
                  kind: 'SCALAR',
                  name: 'Any',
                },
              },
            ],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'OBJECT',
        name: 'KommuneDesignColors',
        fields: [
          {
            name: 'primary',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'primaryLight',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'OBJECT',
        name: 'KommuneDesignFonts',
        fields: [
          {
            name: 'body',
            type: {
              kind: 'SCALAR',
              name: 'Any',
            },
            args: [],
          },
          {
            name: 'headings',
            type: {
              kind: 'SCALAR',
              name: 'Any',
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'OBJECT',
        name: 'LocationFormularFeld',
        fields: [
          {
            name: 'angezeigterName',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'anweisung',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'encrypted',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'Boolean',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'id',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'UUID',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'inhaltsTag',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'istRelevant',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'BoolExpression',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'order',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'Int',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'schritt',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'OBJECT',
                name: 'FormularSchritt',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'schrittId',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'UUID',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'slotName',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'slotValueType',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'Any',
              },
            },
            args: [],
          },
        ],
        interfaces: [
          {
            kind: 'INTERFACE',
            name: 'FormularFeld',
          },
        ],
      },
      {
        kind: 'OBJECT',
        name: 'LoginBuergerWeakPayload',
        fields: [
          {
            name: 'loginPayload',
            type: {
              kind: 'OBJECT',
              name: 'LoginPayload',
              ofType: null,
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'OBJECT',
        name: 'LoginPayload',
        fields: [
          {
            name: 'result',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'Any',
              },
            },
            args: [],
          },
          {
            name: 'token',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'OBJECT',
        name: 'Medien',
        fields: [
          {
            name: 'createdAt',
            type: {
              kind: 'SCALAR',
              name: 'DateTime',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'filename',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'filesize',
            type: {
              kind: 'SCALAR',
              name: 'Float',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'height',
            type: {
              kind: 'SCALAR',
              name: 'Float',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'id',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'kommune',
            type: {
              kind: 'OBJECT',
              name: 'BereichBKommune',
              ofType: null,
            },
            args: [
              {
                name: 'fallbackLocale',
                type: {
                  kind: 'SCALAR',
                  name: 'Any',
                },
              },
              {
                name: 'locale',
                type: {
                  kind: 'SCALAR',
                  name: 'Any',
                },
              },
            ],
          },
          {
            name: 'mimeType',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'sha1',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'sizes',
            type: {
              kind: 'OBJECT',
              name: 'Medien_Sizes',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'updatedAt',
            type: {
              kind: 'SCALAR',
              name: 'DateTime',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'url',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'width',
            type: {
              kind: 'SCALAR',
              name: 'Float',
              ofType: null,
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'OBJECT',
        name: 'Medien_Sizes',
        fields: [
          {
            name: 'thumbnail',
            type: {
              kind: 'OBJECT',
              name: 'Medien_Sizes_Thumbnail',
              ofType: null,
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'OBJECT',
        name: 'Medien_Sizes_Thumbnail',
        fields: [
          {
            name: 'filename',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'filesize',
            type: {
              kind: 'SCALAR',
              name: 'Float',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'height',
            type: {
              kind: 'SCALAR',
              name: 'Float',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'mimeType',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'url',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'width',
            type: {
              kind: 'SCALAR',
              name: 'Float',
              ofType: null,
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'INTERFACE',
        name: 'Mitarbeiter',
        fields: [
          {
            name: 'eMail',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'gruppen',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'LIST',
                ofType: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'OBJECT',
                    name: 'MitarbeiterGruppe',
                    ofType: null,
                  },
                },
              },
            },
            args: [],
          },
          {
            name: 'id',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'UUID',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'kommune',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'OBJECT',
                name: 'Kommune',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'kommuneId',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'UUID',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'nachname',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'organisationPrivateKey',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'publicKey',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'vorname',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null,
              },
            },
            args: [],
          },
        ],
        interfaces: [
          {
            kind: 'INTERFACE',
            name: 'User',
          },
        ],
        possibleTypes: [
          {
            kind: 'OBJECT',
            name: 'BackofficeUser',
          },
        ],
      },
      {
        kind: 'OBJECT',
        name: 'MitarbeiterConnection',
        fields: [
          {
            name: 'edges',
            type: {
              kind: 'LIST',
              ofType: {
                kind: 'NON_NULL',
                ofType: {
                  kind: 'OBJECT',
                  name: 'MitarbeiterEdge',
                  ofType: null,
                },
              },
            },
            args: [],
          },
          {
            name: 'nodes',
            type: {
              kind: 'LIST',
              ofType: {
                kind: 'NON_NULL',
                ofType: {
                  kind: 'INTERFACE',
                  name: 'Mitarbeiter',
                  ofType: null,
                },
              },
            },
            args: [],
          },
          {
            name: 'pageInfo',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'OBJECT',
                name: 'PageInfo',
                ofType: null,
              },
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'OBJECT',
        name: 'MitarbeiterEdge',
        fields: [
          {
            name: 'cursor',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'node',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'INTERFACE',
                name: 'Mitarbeiter',
                ofType: null,
              },
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'OBJECT',
        name: 'MitarbeiterGruppe',
        fields: [
          {
            name: 'id',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'UUID',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'name',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'ordinal',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'Int',
                ofType: null,
              },
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'OBJECT',
        name: 'Mutation',
        fields: [
          {
            name: 'addGroupToMitarbeiter',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'OBJECT',
                name: 'AddGroupToMitarbeiterPayload',
                ofType: null,
              },
            },
            args: [
              {
                name: 'input',
                type: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'SCALAR',
                    name: 'Any',
                  },
                },
              },
            ],
          },
          {
            name: 'addProzessGroup',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'OBJECT',
                name: 'AddProzessGroupPayload',
                ofType: null,
              },
            },
            args: [
              {
                name: 'addProzessGroupInput',
                type: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'SCALAR',
                    name: 'Any',
                  },
                },
              },
            ],
          },
          {
            name: 'addUserGroup',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'OBJECT',
                name: 'AddUserGroupPayload',
                ofType: null,
              },
            },
            args: [
              {
                name: 'input',
                type: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'SCALAR',
                    name: 'Any',
                  },
                },
              },
            ],
          },
          {
            name: 'ausfuehrungContinue',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'OBJECT',
                name: 'AusfuehrungContinuePayload',
                ofType: null,
              },
            },
            args: [
              {
                name: 'input',
                type: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'SCALAR',
                    name: 'Any',
                  },
                },
              },
            ],
          },
          {
            name: 'ausfuehrungCreate',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'OBJECT',
                name: 'AusfuehrungCreatePayload',
                ofType: null,
              },
            },
            args: [
              {
                name: 'input',
                type: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'SCALAR',
                    name: 'Any',
                  },
                },
              },
            ],
          },
          {
            name: 'ausfuehrungFolgen',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'OBJECT',
                name: 'AusfuehrungFolgenPayload',
                ofType: null,
              },
            },
            args: [
              {
                name: 'input',
                type: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'SCALAR',
                    name: 'Any',
                  },
                },
              },
            ],
          },
          {
            name: 'backofficeUserUpdate',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'OBJECT',
                name: 'BackofficeUserUpdatePayload',
                ofType: null,
              },
            },
            args: [
              {
                name: 'input',
                type: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'SCALAR',
                    name: 'Any',
                  },
                },
              },
            ],
          },
          {
            name: 'bezahlenSchrittUrl',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'OBJECT',
                name: 'BezahlenSchrittUrlPayload',
                ofType: null,
              },
            },
            args: [
              {
                name: 'input',
                type: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'SCALAR',
                    name: 'Any',
                  },
                },
              },
            ],
          },
          {
            name: 'deleteProzessGroup',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'OBJECT',
                name: 'DeleteProzessGroupPayload',
                ofType: null,
              },
            },
            args: [
              {
                name: 'deleteProzessGroupInput',
                type: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'SCALAR',
                    name: 'Any',
                  },
                },
              },
            ],
          },
          {
            name: 'deleteUserGroup',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'OBJECT',
                name: 'DeleteUserGroupPayload',
                ofType: null,
              },
            },
            args: [
              {
                name: 'input',
                type: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'SCALAR',
                    name: 'Any',
                  },
                },
              },
            ],
          },
          {
            name: 'login',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'OBJECT',
                name: 'LoginPayload',
                ofType: null,
              },
            },
            args: [
              {
                name: 'input',
                type: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'SCALAR',
                    name: 'Any',
                  },
                },
              },
            ],
          },
          {
            name: 'loginBuergerWeak',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'OBJECT',
                name: 'LoginBuergerWeakPayload',
                ofType: null,
              },
            },
            args: [
              {
                name: 'input',
                type: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'SCALAR',
                    name: 'Any',
                  },
                },
              },
            ],
          },
          {
            name: 'notificationTokenRegister',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'OBJECT',
                name: 'NotificationTokenRegisterPayload',
                ofType: null,
              },
            },
            args: [
              {
                name: 'input',
                type: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'SCALAR',
                    name: 'Any',
                  },
                },
              },
            ],
          },
          {
            name: 'personalDataUpdate',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'OBJECT',
                name: 'PersonalDataUpdatePayload',
                ofType: null,
              },
            },
            args: [
              {
                name: 'input',
                type: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'SCALAR',
                    name: 'Any',
                  },
                },
              },
            ],
          },
          {
            name: 'prozessUpsert',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'OBJECT',
                name: 'ProzessUpsertPayload',
                ofType: null,
              },
            },
            args: [
              {
                name: 'input',
                type: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'SCALAR',
                    name: 'Any',
                  },
                },
              },
            ],
          },
          {
            name: 'prozessVersionErstellen',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'OBJECT',
                name: 'ProzessVersionErstellenPayload',
                ofType: null,
              },
            },
            args: [
              {
                name: 'input',
                type: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'SCALAR',
                    name: 'Any',
                  },
                },
              },
            ],
          },
          {
            name: 'prozessVersionVeroeffentlichen',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'OBJECT',
                name: 'ProzessVersionVeroeffentlichenPayload',
                ofType: null,
              },
            },
            args: [
              {
                name: 'input',
                type: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'SCALAR',
                    name: 'Any',
                  },
                },
              },
            ],
          },
          {
            name: 'registerBuergerWeak',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'OBJECT',
                name: 'RegisterBuergerWeakPayload',
                ofType: null,
              },
            },
            args: [
              {
                name: 'input',
                type: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'SCALAR',
                    name: 'Any',
                  },
                },
              },
            ],
          },
          {
            name: 'removeGroupFromMitarbeiter',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'OBJECT',
                name: 'RemoveGroupFromMitarbeiterPayload',
                ofType: null,
              },
            },
            args: [
              {
                name: 'input',
                type: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'SCALAR',
                    name: 'Any',
                  },
                },
              },
            ],
          },
          {
            name: 'servicePortalLinkUpsert',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'OBJECT',
                name: 'ServicePortalLinkUpsertPayload',
                ofType: null,
              },
            },
            args: [
              {
                name: 'input',
                type: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'SCALAR',
                    name: 'Any',
                  },
                },
              },
            ],
          },
          {
            name: 'setGroupToProzess',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'OBJECT',
                name: 'SetGroupToProzessPayload',
                ofType: null,
              },
            },
            args: [
              {
                name: 'input',
                type: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'SCALAR',
                    name: 'Any',
                  },
                },
              },
            ],
          },
          {
            name: 'untereinheitCreate',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'OBJECT',
                name: 'UntereinheitCreatePayload',
                ofType: null,
              },
            },
            args: [
              {
                name: 'input',
                type: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'SCALAR',
                    name: 'Any',
                  },
                },
              },
            ],
          },
          {
            name: 'untereinheitDelete',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'OBJECT',
                name: 'UntereinheitDeletePayload',
                ofType: null,
              },
            },
            args: [
              {
                name: 'input',
                type: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'SCALAR',
                    name: 'Any',
                  },
                },
              },
            ],
          },
          {
            name: 'untereinheitUpdate',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'OBJECT',
                name: 'UntereinheitUpdatePayload',
                ofType: null,
              },
            },
            args: [
              {
                name: 'input',
                type: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'SCALAR',
                    name: 'Any',
                  },
                },
              },
            ],
          },
          {
            name: 'updateProzessGroup',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'OBJECT',
                name: 'UpdateProzessGroupPayload',
                ofType: null,
              },
            },
            args: [
              {
                name: 'updateProzessGroupInput',
                type: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'SCALAR',
                    name: 'Any',
                  },
                },
              },
            ],
          },
          {
            name: 'updateUserGroup',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'OBJECT',
                name: 'UpdateUserGroupPayload',
                ofType: null,
              },
            },
            args: [
              {
                name: 'input',
                type: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'SCALAR',
                    name: 'Any',
                  },
                },
              },
            ],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'OBJECT',
        name: 'NewsArtikel',
        fields: [
          {
            name: '_status',
            type: {
              kind: 'SCALAR',
              name: 'Any',
            },
            args: [],
          },
          {
            name: 'createdAt',
            type: {
              kind: 'SCALAR',
              name: 'DateTime',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'datum',
            type: {
              kind: 'SCALAR',
              name: 'DateTime',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'id',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'importId',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'inhalte',
            type: {
              kind: 'LIST',
              ofType: {
                kind: 'NON_NULL',
                ofType: {
                  kind: 'UNION',
                  name: 'NewsArtikel_Inhalte',
                  ofType: null,
                },
              },
            },
            args: [],
          },
          {
            name: 'integration',
            type: {
              kind: 'OBJECT',
              name: 'Integration',
              ofType: null,
            },
            args: [
              {
                name: 'fallbackLocale',
                type: {
                  kind: 'SCALAR',
                  name: 'Any',
                },
              },
              {
                name: 'locale',
                type: {
                  kind: 'SCALAR',
                  name: 'Any',
                },
              },
            ],
          },
          {
            name: 'kommune',
            type: {
              kind: 'OBJECT',
              name: 'BereichBKommune',
              ofType: null,
            },
            args: [
              {
                name: 'fallbackLocale',
                type: {
                  kind: 'SCALAR',
                  name: 'Any',
                },
              },
              {
                name: 'locale',
                type: {
                  kind: 'SCALAR',
                  name: 'Any',
                },
              },
            ],
          },
          {
            name: 'ordinal',
            type: {
              kind: 'SCALAR',
              name: 'Float',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'teaser',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'titel',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'titelbild',
            type: {
              kind: 'OBJECT',
              name: 'Veranstaltungsmedien',
              ofType: null,
            },
            args: [
              {
                name: 'fallbackLocale',
                type: {
                  kind: 'SCALAR',
                  name: 'Any',
                },
              },
              {
                name: 'locale',
                type: {
                  kind: 'SCALAR',
                  name: 'Any',
                },
              },
              {
                name: 'where',
                type: {
                  kind: 'SCALAR',
                  name: 'Any',
                },
              },
            ],
          },
          {
            name: 'untereinheit',
            type: {
              kind: 'OBJECT',
              name: 'BereichBUntereinheit',
              ofType: null,
            },
            args: [
              {
                name: 'fallbackLocale',
                type: {
                  kind: 'SCALAR',
                  name: 'Any',
                },
              },
              {
                name: 'locale',
                type: {
                  kind: 'SCALAR',
                  name: 'Any',
                },
              },
            ],
          },
          {
            name: 'updatedAt',
            type: {
              kind: 'SCALAR',
              name: 'DateTime',
              ofType: null,
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'UNION',
        name: 'NewsArtikel_Inhalte',
        possibleTypes: [
          {
            kind: 'OBJECT',
            name: 'ImageBlockVeranstaltungsmedien',
          },
          {
            kind: 'OBJECT',
            name: 'RichTextBlock',
          },
        ],
      },
      {
        kind: 'OBJECT',
        name: 'NewsArtikels',
        fields: [
          {
            name: 'docs',
            type: {
              kind: 'LIST',
              ofType: {
                kind: 'OBJECT',
                name: 'NewsArtikel',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'hasNextPage',
            type: {
              kind: 'SCALAR',
              name: 'Boolean',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'hasPrevPage',
            type: {
              kind: 'SCALAR',
              name: 'Boolean',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'limit',
            type: {
              kind: 'SCALAR',
              name: 'Int',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'nextPage',
            type: {
              kind: 'SCALAR',
              name: 'Int',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'offset',
            type: {
              kind: 'SCALAR',
              name: 'Int',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'page',
            type: {
              kind: 'SCALAR',
              name: 'Int',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'pagingCounter',
            type: {
              kind: 'SCALAR',
              name: 'Int',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'prevPage',
            type: {
              kind: 'SCALAR',
              name: 'Int',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'totalDocs',
            type: {
              kind: 'SCALAR',
              name: 'Int',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'totalPages',
            type: {
              kind: 'SCALAR',
              name: 'Int',
              ofType: null,
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'OBJECT',
        name: 'NotificationTokenRegisterPayload',
        fields: [
          {
            name: 'buerger',
            type: {
              kind: 'OBJECT',
              name: 'Buerger',
              ofType: null,
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'OBJECT',
        name: 'Ort',
        fields: [
          {
            name: 'id',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'name',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null,
              },
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'OBJECT',
        name: 'PageInfo',
        fields: [
          {
            name: 'endCursor',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'hasNextPage',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'Boolean',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'hasPreviousPage',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'Boolean',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'startCursor',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'OBJECT',
        name: 'PersonalDataUpdatePayload',
        fields: [
          {
            name: 'buerger',
            type: {
              kind: 'OBJECT',
              name: 'Buerger',
              ofType: null,
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'OBJECT',
        name: 'PlainSlotValue',
        fields: [
          {
            name: 'bearbeitung',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'OBJECT',
                name: 'Bearbeitung',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'id',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'UUID',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'slotName',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'slotValueCount',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'Int',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'value',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'JSON',
                ofType: null,
              },
            },
            args: [],
          },
        ],
        interfaces: [
          {
            kind: 'INTERFACE',
            name: 'SlotValue',
          },
        ],
      },
      {
        kind: 'OBJECT',
        name: 'Poi',
        fields: [
          {
            name: 'additionalInformation',
            type: {
              kind: 'LIST',
              ofType: {
                kind: 'NON_NULL',
                ofType: {
                  kind: 'OBJECT',
                  name: 'Poi_AdditionalInformation',
                  ofType: null,
                },
              },
            },
            args: [],
          },
          {
            name: 'category',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'OBJECT',
                name: 'PoiCategory',
                ofType: null,
              },
            },
            args: [
              {
                name: 'fallbackLocale',
                type: {
                  kind: 'SCALAR',
                  name: 'Any',
                },
              },
              {
                name: 'locale',
                type: {
                  kind: 'SCALAR',
                  name: 'Any',
                },
              },
            ],
          },
          {
            name: 'contents',
            type: {
              kind: 'LIST',
              ofType: {
                kind: 'NON_NULL',
                ofType: {
                  kind: 'UNION',
                  name: 'Poi_Contents',
                  ofType: null,
                },
              },
            },
            args: [],
          },
          {
            name: 'createdAt',
            type: {
              kind: 'SCALAR',
              name: 'DateTime',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'id',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'image',
            type: {
              kind: 'OBJECT',
              name: 'Poi_Image',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'kommune',
            type: {
              kind: 'OBJECT',
              name: 'BereichBKommune',
              ofType: null,
            },
            args: [
              {
                name: 'fallbackLocale',
                type: {
                  kind: 'SCALAR',
                  name: 'Any',
                },
              },
              {
                name: 'locale',
                type: {
                  kind: 'SCALAR',
                  name: 'Any',
                },
              },
            ],
          },
          {
            name: 'lockedForImport',
            type: {
              kind: 'SCALAR',
              name: 'Boolean',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'objectId',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'position',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'LIST',
                ofType: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'SCALAR',
                    name: 'Float',
                    ofType: null,
                  },
                },
              },
            },
            args: [],
          },
          {
            name: 'positionString',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'title',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'updatedAt',
            type: {
              kind: 'SCALAR',
              name: 'DateTime',
              ofType: null,
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'OBJECT',
        name: 'PoiCategories',
        fields: [
          {
            name: 'docs',
            type: {
              kind: 'LIST',
              ofType: {
                kind: 'OBJECT',
                name: 'PoiCategory',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'hasNextPage',
            type: {
              kind: 'SCALAR',
              name: 'Boolean',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'hasPrevPage',
            type: {
              kind: 'SCALAR',
              name: 'Boolean',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'limit',
            type: {
              kind: 'SCALAR',
              name: 'Int',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'nextPage',
            type: {
              kind: 'SCALAR',
              name: 'Int',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'offset',
            type: {
              kind: 'SCALAR',
              name: 'Int',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'page',
            type: {
              kind: 'SCALAR',
              name: 'Int',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'pagingCounter',
            type: {
              kind: 'SCALAR',
              name: 'Int',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'prevPage',
            type: {
              kind: 'SCALAR',
              name: 'Int',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'totalDocs',
            type: {
              kind: 'SCALAR',
              name: 'Int',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'totalPages',
            type: {
              kind: 'SCALAR',
              name: 'Int',
              ofType: null,
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'OBJECT',
        name: 'PoiCategory',
        fields: [
          {
            name: 'createdAt',
            type: {
              kind: 'SCALAR',
              name: 'DateTime',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'icon',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'OBJECT',
                name: 'PoiIcon',
                ofType: null,
              },
            },
            args: [
              {
                name: 'fallbackLocale',
                type: {
                  kind: 'SCALAR',
                  name: 'Any',
                },
              },
              {
                name: 'locale',
                type: {
                  kind: 'SCALAR',
                  name: 'Any',
                },
              },
              {
                name: 'where',
                type: {
                  kind: 'SCALAR',
                  name: 'Any',
                },
              },
            ],
          },
          {
            name: 'id',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'kommune',
            type: {
              kind: 'OBJECT',
              name: 'BereichBKommune',
              ofType: null,
            },
            args: [
              {
                name: 'fallbackLocale',
                type: {
                  kind: 'SCALAR',
                  name: 'Any',
                },
              },
              {
                name: 'locale',
                type: {
                  kind: 'SCALAR',
                  name: 'Any',
                },
              },
            ],
          },
          {
            name: 'supercategory',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'OBJECT',
                name: 'PoiSupercategory',
                ofType: null,
              },
            },
            args: [
              {
                name: 'fallbackLocale',
                type: {
                  kind: 'SCALAR',
                  name: 'Any',
                },
              },
              {
                name: 'locale',
                type: {
                  kind: 'SCALAR',
                  name: 'Any',
                },
              },
            ],
          },
          {
            name: 'title',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'updatedAt',
            type: {
              kind: 'SCALAR',
              name: 'DateTime',
              ofType: null,
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'OBJECT',
        name: 'PoiIcon',
        fields: [
          {
            name: 'createdAt',
            type: {
              kind: 'SCALAR',
              name: 'DateTime',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'filename',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'filesize',
            type: {
              kind: 'SCALAR',
              name: 'Float',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'height',
            type: {
              kind: 'SCALAR',
              name: 'Float',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'id',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'kommune',
            type: {
              kind: 'OBJECT',
              name: 'BereichBKommune',
              ofType: null,
            },
            args: [
              {
                name: 'fallbackLocale',
                type: {
                  kind: 'SCALAR',
                  name: 'Any',
                },
              },
              {
                name: 'locale',
                type: {
                  kind: 'SCALAR',
                  name: 'Any',
                },
              },
            ],
          },
          {
            name: 'mimeType',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'sha1',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'sizes',
            type: {
              kind: 'OBJECT',
              name: 'PoiIcon_Sizes',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'updatedAt',
            type: {
              kind: 'SCALAR',
              name: 'DateTime',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'url',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'width',
            type: {
              kind: 'SCALAR',
              name: 'Float',
              ofType: null,
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'OBJECT',
        name: 'PoiIcon_Sizes',
        fields: [
          {
            name: 'thumbnail',
            type: {
              kind: 'OBJECT',
              name: 'PoiIcon_Sizes_Thumbnail',
              ofType: null,
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'OBJECT',
        name: 'PoiIcon_Sizes_Thumbnail',
        fields: [
          {
            name: 'filename',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'filesize',
            type: {
              kind: 'SCALAR',
              name: 'Float',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'height',
            type: {
              kind: 'SCALAR',
              name: 'Float',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'mimeType',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'url',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'width',
            type: {
              kind: 'SCALAR',
              name: 'Float',
              ofType: null,
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'OBJECT',
        name: 'PoiSupercategory',
        fields: [
          {
            name: 'createdAt',
            type: {
              kind: 'SCALAR',
              name: 'DateTime',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'id',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'kommune',
            type: {
              kind: 'OBJECT',
              name: 'BereichBKommune',
              ofType: null,
            },
            args: [
              {
                name: 'fallbackLocale',
                type: {
                  kind: 'SCALAR',
                  name: 'Any',
                },
              },
              {
                name: 'locale',
                type: {
                  kind: 'SCALAR',
                  name: 'Any',
                },
              },
            ],
          },
          {
            name: 'title',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'updatedAt',
            type: {
              kind: 'SCALAR',
              name: 'DateTime',
              ofType: null,
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'OBJECT',
        name: 'Poi_AdditionalInformation',
        fields: [
          {
            name: 'heading',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'id',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'kind',
            type: {
              kind: 'SCALAR',
              name: 'Any',
            },
            args: [],
          },
          {
            name: 'text',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'UNION',
        name: 'Poi_Contents',
        possibleTypes: [
          {
            kind: 'OBJECT',
            name: 'ImageBlockMedien',
          },
          {
            kind: 'OBJECT',
            name: 'RichTextBlock',
          },
        ],
      },
      {
        kind: 'OBJECT',
        name: 'Poi_Image',
        fields: [
          {
            name: 'external',
            type: {
              kind: 'SCALAR',
              name: 'Boolean',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'internal',
            type: {
              kind: 'OBJECT',
              name: 'Medien',
              ofType: null,
            },
            args: [
              {
                name: 'fallbackLocale',
                type: {
                  kind: 'SCALAR',
                  name: 'Any',
                },
              },
              {
                name: 'locale',
                type: {
                  kind: 'SCALAR',
                  name: 'Any',
                },
              },
              {
                name: 'where',
                type: {
                  kind: 'SCALAR',
                  name: 'Any',
                },
              },
            ],
          },
          {
            name: 'url',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'OBJECT',
        name: 'Pois',
        fields: [
          {
            name: 'docs',
            type: {
              kind: 'LIST',
              ofType: {
                kind: 'OBJECT',
                name: 'Poi',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'hasNextPage',
            type: {
              kind: 'SCALAR',
              name: 'Boolean',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'hasPrevPage',
            type: {
              kind: 'SCALAR',
              name: 'Boolean',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'limit',
            type: {
              kind: 'SCALAR',
              name: 'Int',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'nextPage',
            type: {
              kind: 'SCALAR',
              name: 'Int',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'offset',
            type: {
              kind: 'SCALAR',
              name: 'Int',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'page',
            type: {
              kind: 'SCALAR',
              name: 'Int',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'pagingCounter',
            type: {
              kind: 'SCALAR',
              name: 'Int',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'prevPage',
            type: {
              kind: 'SCALAR',
              name: 'Int',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'totalDocs',
            type: {
              kind: 'SCALAR',
              name: 'Int',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'totalPages',
            type: {
              kind: 'SCALAR',
              name: 'Int',
              ofType: null,
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'OBJECT',
        name: 'ProgrammatischerSchritt',
        fields: [
          {
            name: 'anweisung',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'erledigungDurchBuerger',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'Boolean',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'id',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'UUID',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'implementationAssemblyQualifiedName',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'informationstext',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'mitarbeiterGruppe',
            type: {
              kind: 'OBJECT',
              name: 'MitarbeiterGruppe',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'mitarbeiterGruppeId',
            type: {
              kind: 'SCALAR',
              name: 'UUID',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'prozessVersion',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'OBJECT',
                name: 'ProzessVersion',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'prozessVersionId',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'UUID',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'slots',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'LIST',
                ofType: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'OBJECT',
                    name: 'Slot',
                    ofType: null,
                  },
                },
              },
            },
            args: [],
          },
          {
            name: 'titel',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'verbindungen',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'LIST',
                ofType: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'OBJECT',
                    name: 'Verbindung',
                    ofType: null,
                  },
                },
              },
            },
            args: [],
          },
        ],
        interfaces: [
          {
            kind: 'INTERFACE',
            name: 'Schritt',
          },
        ],
      },
      {
        kind: 'OBJECT',
        name: 'Prozess',
        fields: [
          {
            name: 'currentVersion',
            type: {
              kind: 'OBJECT',
              name: 'ProzessVersion',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'group',
            type: {
              kind: 'OBJECT',
              name: 'ProzessGroup',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'groupId',
            type: {
              kind: 'SCALAR',
              name: 'UUID',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'id',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'UUID',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'kommune',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'OBJECT',
                name: 'Kommune',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'kommuneId',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'UUID',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'latestVersion',
            type: {
              kind: 'OBJECT',
              name: 'ProzessVersion',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'name',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'tags',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'LIST',
                ofType: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'OBJECT',
                    name: 'ProzessTag',
                    ofType: null,
                  },
                },
              },
            },
            args: [],
          },
          {
            name: 'versionen',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'LIST',
                ofType: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'OBJECT',
                    name: 'ProzessVersion',
                    ofType: null,
                  },
                },
              },
            },
            args: [],
          },
          {
            name: 'versionenCount',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'Int',
                ofType: null,
              },
            },
            args: [],
          },
        ],
        interfaces: [
          {
            kind: 'INTERFACE',
            name: 'UserFacingProzess',
          },
        ],
      },
      {
        kind: 'OBJECT',
        name: 'ProzessGroup',
        fields: [
          {
            name: 'id',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'UUID',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'kommune',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'OBJECT',
                name: 'Kommune',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'kommuneId',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'UUID',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'name',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'prozesse',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'LIST',
                ofType: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'INTERFACE',
                    name: 'UserFacingProzess',
                    ofType: null,
                  },
                },
              },
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'OBJECT',
        name: 'ProzessGroupAssignedToProzess',
        fields: [
          {
            name: 'message',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null,
              },
            },
            args: [],
          },
        ],
        interfaces: [
          {
            kind: 'INTERFACE',
            name: 'Error',
          },
        ],
      },
      {
        kind: 'OBJECT',
        name: 'ProzessGroupNotFound',
        fields: [
          {
            name: 'message',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null,
              },
            },
            args: [],
          },
        ],
        interfaces: [
          {
            kind: 'INTERFACE',
            name: 'Error',
          },
        ],
      },
      {
        kind: 'OBJECT',
        name: 'ProzessTag',
        fields: [
          {
            name: 'id',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'UUID',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'prozesse',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'LIST',
                ofType: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'OBJECT',
                    name: 'Prozess',
                    ofType: null,
                  },
                },
              },
            },
            args: [],
          },
          {
            name: 'tag',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null,
              },
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'OBJECT',
        name: 'ProzessUpsertPayload',
        fields: [
          {
            name: 'prozess',
            type: {
              kind: 'OBJECT',
              name: 'Prozess',
              ofType: null,
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'OBJECT',
        name: 'ProzessVersion',
        fields: [
          {
            name: 'alleSchritte',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'LIST',
                ofType: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'INTERFACE',
                    name: 'Schritt',
                    ofType: null,
                  },
                },
              },
            },
            args: [],
          },
          {
            name: 'beschreibungstext',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'StringExpression',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'createdAt',
            type: {
              kind: 'SCALAR',
              name: 'Instant',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'ersteSchritte',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'LIST',
                ofType: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'INTERFACE',
                    name: 'Schritt',
                    ofType: null,
                  },
                },
              },
            },
            args: [],
          },
          {
            name: 'id',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'UUID',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'prozess',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'OBJECT',
                name: 'Prozess',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'prozessId',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'UUID',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'publishedAt',
            type: {
              kind: 'SCALAR',
              name: 'Instant',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'start',
            type: {
              kind: 'INTERFACE',
              name: 'Schritt',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'vergangeneSchritte',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'LIST',
                ofType: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'OBJECT',
                    name: 'VergangenerSchritt',
                    ofType: null,
                  },
                },
              },
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'OBJECT',
        name: 'ProzessVersionErstellenPayload',
        fields: [
          {
            name: 'prozess',
            type: {
              kind: 'OBJECT',
              name: 'Prozess',
              ofType: null,
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'OBJECT',
        name: 'ProzessVersionVeroeffentlichenPayload',
        fields: [
          {
            name: 'prozessVersion',
            type: {
              kind: 'OBJECT',
              name: 'ProzessVersion',
              ofType: null,
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'OBJECT',
        name: 'Query',
        fields: [
          {
            name: 'ausfuehrung',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'OBJECT',
                name: 'Ausfuehrung',
                ofType: null,
              },
            },
            args: [
              {
                name: 'id',
                type: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'SCALAR',
                    name: 'UUID',
                    ofType: null,
                  },
                },
              },
            ],
          },
          {
            name: 'kommune',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'OBJECT',
                name: 'Kommune',
                ofType: null,
              },
            },
            args: [
              {
                name: 'kommuneId',
                type: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'SCALAR',
                    name: 'UUID',
                    ofType: null,
                  },
                },
              },
            ],
          },
          {
            name: 'kommunen',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'LIST',
                ofType: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'OBJECT',
                    name: 'Kommune',
                    ofType: null,
                  },
                },
              },
            },
            args: [],
          },
          {
            name: 'my',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'INTERFACE',
                name: 'User',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'newsArtikel',
            type: {
              kind: 'OBJECT',
              name: 'NewsArtikel',
              ofType: null,
            },
            args: [
              {
                name: 'id',
                type: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'SCALAR',
                    name: 'String',
                    ofType: null,
                  },
                },
              },
            ],
          },
          {
            name: 'newsArtikelList',
            type: {
              kind: 'OBJECT',
              name: 'NewsArtikels',
              ofType: null,
            },
            args: [
              {
                name: 'limit',
                type: {
                  kind: 'SCALAR',
                  name: 'Int',
                  ofType: null,
                },
              },
              {
                name: 'page',
                type: {
                  kind: 'SCALAR',
                  name: 'Int',
                  ofType: null,
                },
              },
              {
                name: 'sort',
                type: {
                  kind: 'SCALAR',
                  name: 'String',
                  ofType: null,
                },
              },
              {
                name: 'where',
                type: {
                  kind: 'SCALAR',
                  name: 'Any',
                },
              },
            ],
          },
          {
            name: 'poi',
            type: {
              kind: 'OBJECT',
              name: 'Poi',
              ofType: null,
            },
            args: [
              {
                name: 'id',
                type: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'SCALAR',
                    name: 'String',
                    ofType: null,
                  },
                },
              },
            ],
          },
          {
            name: 'poiCategories',
            type: {
              kind: 'OBJECT',
              name: 'PoiCategories',
              ofType: null,
            },
            args: [
              {
                name: 'limit',
                type: {
                  kind: 'SCALAR',
                  name: 'Int',
                  ofType: null,
                },
              },
              {
                name: 'sort',
                type: {
                  kind: 'SCALAR',
                  name: 'String',
                  ofType: null,
                },
              },
              {
                name: 'where',
                type: {
                  kind: 'SCALAR',
                  name: 'Any',
                },
              },
            ],
          },
          {
            name: 'pois',
            type: {
              kind: 'OBJECT',
              name: 'Pois',
              ofType: null,
            },
            args: [
              {
                name: 'limit',
                type: {
                  kind: 'SCALAR',
                  name: 'Int',
                  ofType: null,
                },
              },
              {
                name: 'sort',
                type: {
                  kind: 'SCALAR',
                  name: 'String',
                  ofType: null,
                },
              },
              {
                name: 'where',
                type: {
                  kind: 'SCALAR',
                  name: 'Any',
                },
              },
            ],
          },
          {
            name: 'sensor',
            type: {
              kind: 'OBJECT',
              name: 'Sensoren',
              ofType: null,
            },
            args: [
              {
                name: 'id',
                type: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'SCALAR',
                    name: 'String',
                    ofType: null,
                  },
                },
              },
            ],
          },
          {
            name: 'sensorArten',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'OBJECT',
                name: 'SensorArts',
                ofType: null,
              },
            },
            args: [
              {
                name: 'where',
                type: {
                  kind: 'SCALAR',
                  name: 'Any',
                },
              },
            ],
          },
          {
            name: 'sensoren',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'OBJECT',
                name: 'Sensorens',
                ofType: null,
              },
            },
            args: [
              {
                name: 'limit',
                type: {
                  kind: 'SCALAR',
                  name: 'Int',
                  ofType: null,
                },
              },
              {
                name: 'sort',
                type: {
                  kind: 'SCALAR',
                  name: 'String',
                  ofType: null,
                },
              },
              {
                name: 'where',
                type: {
                  kind: 'SCALAR',
                  name: 'Any',
                },
              },
            ],
          },
          {
            name: 'veranstaltungskalender',
            type: {
              kind: 'OBJECT',
              name: 'Veranstaltungskalender',
              ofType: null,
            },
            args: [
              {
                name: 'id',
                type: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'SCALAR',
                    name: 'String',
                    ofType: null,
                  },
                },
              },
            ],
          },
          {
            name: 'veranstaltungskalenderKategories',
            type: {
              kind: 'OBJECT',
              name: 'VeranstaltungskalenderKategories',
              ofType: null,
            },
            args: [
              {
                name: 'limit',
                type: {
                  kind: 'SCALAR',
                  name: 'Int',
                  ofType: null,
                },
              },
              {
                name: 'where',
                type: {
                  kind: 'SCALAR',
                  name: 'Any',
                },
              },
            ],
          },
          {
            name: 'veranstaltungskalenderList',
            type: {
              kind: 'OBJECT',
              name: 'Veranstaltungskalenders',
              ofType: null,
            },
            args: [
              {
                name: 'limit',
                type: {
                  kind: 'SCALAR',
                  name: 'Int',
                  ofType: null,
                },
              },
              {
                name: 'sort',
                type: {
                  kind: 'SCALAR',
                  name: 'String',
                  ofType: null,
                },
              },
              {
                name: 'where',
                type: {
                  kind: 'SCALAR',
                  name: 'Any',
                },
              },
            ],
          },
          {
            name: 'veranstaltungskalenderQuellen',
            type: {
              kind: 'OBJECT',
              name: 'VeranstaltungskalenderQuellen',
              ofType: null,
            },
            args: [
              {
                name: 'limit',
                type: {
                  kind: 'SCALAR',
                  name: 'Int',
                  ofType: null,
                },
              },
              {
                name: 'where',
                type: {
                  kind: 'SCALAR',
                  name: 'Any',
                },
              },
            ],
          },
          {
            name: 'veranstalungskalender',
            type: {
              kind: 'OBJECT',
              name: 'Veranstaltungskalender',
              ofType: null,
            },
            args: [
              {
                name: 'id',
                type: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'SCALAR',
                    name: 'String',
                    ofType: null,
                  },
                },
              },
            ],
          },
          {
            name: 'wettermesspunkteList',
            type: {
              kind: 'OBJECT',
              name: 'Wettermesspunktes',
              ofType: null,
            },
            args: [
              {
                name: 'where',
                type: {
                  kind: 'SCALAR',
                  name: 'Any',
                },
              },
            ],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'OBJECT',
        name: 'RegisterBuergerWeakPayload',
        fields: [
          {
            name: 'buergerId',
            type: {
              kind: 'SCALAR',
              name: 'UUID',
              ofType: null,
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'OBJECT',
        name: 'RemoveGroupFromMitarbeiterPayload',
        fields: [
          {
            name: 'mitarbeiter',
            type: {
              kind: 'INTERFACE',
              name: 'Mitarbeiter',
              ofType: null,
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'OBJECT',
        name: 'RichTextBlock',
        fields: [
          {
            name: 'blockName',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'blockType',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'content',
            type: {
              kind: 'SCALAR',
              name: 'JSON',
              ofType: null,
            },
            args: [
              {
                name: 'depth',
                type: {
                  kind: 'SCALAR',
                  name: 'Int',
                  ofType: null,
                },
              },
            ],
          },
          {
            name: 'id',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'INTERFACE',
        name: 'Schritt',
        fields: [
          {
            name: 'anweisung',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'erledigungDurchBuerger',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'Boolean',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'id',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'UUID',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'informationstext',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'mitarbeiterGruppe',
            type: {
              kind: 'OBJECT',
              name: 'MitarbeiterGruppe',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'mitarbeiterGruppeId',
            type: {
              kind: 'SCALAR',
              name: 'UUID',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'prozessVersion',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'OBJECT',
                name: 'ProzessVersion',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'prozessVersionId',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'UUID',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'slots',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'LIST',
                ofType: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'OBJECT',
                    name: 'Slot',
                    ofType: null,
                  },
                },
              },
            },
            args: [],
          },
          {
            name: 'titel',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'verbindungen',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'LIST',
                ofType: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'OBJECT',
                    name: 'Verbindung',
                    ofType: null,
                  },
                },
              },
            },
            args: [],
          },
        ],
        interfaces: [],
        possibleTypes: [
          {
            kind: 'OBJECT',
            name: 'BezahlenSchritt',
          },
          {
            kind: 'OBJECT',
            name: 'EmailForwarderExportSchritt',
          },
          {
            kind: 'OBJECT',
            name: 'EndeSchritt',
          },
          {
            kind: 'OBJECT',
            name: 'FormularSchritt',
          },
          {
            kind: 'OBJECT',
            name: 'GovManagerRestExportSchritt',
          },
          {
            kind: 'OBJECT',
            name: 'ProgrammatischerSchritt',
          },
          {
            kind: 'OBJECT',
            name: 'WarteAufBezahlungAbgeschlossenSchritt',
          },
          {
            kind: 'OBJECT',
            name: 'WarteAufGovManagerSchritt',
          },
        ],
      },
      {
        kind: 'OBJECT',
        name: 'SensorArt',
        fields: [
          {
            name: 'createdAt',
            type: {
              kind: 'SCALAR',
              name: 'DateTime',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'einheit',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'icon',
            type: {
              kind: 'OBJECT',
              name: 'PoiIcon',
              ofType: null,
            },
            args: [
              {
                name: 'fallbackLocale',
                type: {
                  kind: 'SCALAR',
                  name: 'Any',
                },
              },
              {
                name: 'locale',
                type: {
                  kind: 'SCALAR',
                  name: 'Any',
                },
              },
              {
                name: 'where',
                type: {
                  kind: 'SCALAR',
                  name: 'Any',
                },
              },
            ],
          },
          {
            name: 'id',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'kommune',
            type: {
              kind: 'OBJECT',
              name: 'BereichBKommune',
              ofType: null,
            },
            args: [
              {
                name: 'fallbackLocale',
                type: {
                  kind: 'SCALAR',
                  name: 'Any',
                },
              },
              {
                name: 'locale',
                type: {
                  kind: 'SCALAR',
                  name: 'Any',
                },
              },
            ],
          },
          {
            name: 'name',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'title',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'updatedAt',
            type: {
              kind: 'SCALAR',
              name: 'DateTime',
              ofType: null,
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'OBJECT',
        name: 'SensorArts',
        fields: [
          {
            name: 'docs',
            type: {
              kind: 'LIST',
              ofType: {
                kind: 'OBJECT',
                name: 'SensorArt',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'hasNextPage',
            type: {
              kind: 'SCALAR',
              name: 'Boolean',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'hasPrevPage',
            type: {
              kind: 'SCALAR',
              name: 'Boolean',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'limit',
            type: {
              kind: 'SCALAR',
              name: 'Int',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'nextPage',
            type: {
              kind: 'SCALAR',
              name: 'Int',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'offset',
            type: {
              kind: 'SCALAR',
              name: 'Int',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'page',
            type: {
              kind: 'SCALAR',
              name: 'Int',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'pagingCounter',
            type: {
              kind: 'SCALAR',
              name: 'Int',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'prevPage',
            type: {
              kind: 'SCALAR',
              name: 'Int',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'totalDocs',
            type: {
              kind: 'SCALAR',
              name: 'Int',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'totalPages',
            type: {
              kind: 'SCALAR',
              name: 'Int',
              ofType: null,
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'OBJECT',
        name: 'SensorHistorieEintrag',
        fields: [
          {
            name: 'time',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'DateTime',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'value',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'Float',
                ofType: null,
              },
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'OBJECT',
        name: 'Sensoren',
        fields: [
          {
            name: 'createdAt',
            type: {
              kind: 'SCALAR',
              name: 'DateTime',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'felder',
            type: {
              kind: 'LIST',
              ofType: {
                kind: 'NON_NULL',
                ofType: {
                  kind: 'OBJECT',
                  name: 'Sensoren_Felder',
                  ofType: null,
                },
              },
            },
            args: [],
          },
          {
            name: 'icon',
            type: {
              kind: 'OBJECT',
              name: 'PoiIcon',
              ofType: null,
            },
            args: [
              {
                name: 'fallbackLocale',
                type: {
                  kind: 'SCALAR',
                  name: 'Any',
                },
              },
              {
                name: 'locale',
                type: {
                  kind: 'SCALAR',
                  name: 'Any',
                },
              },
              {
                name: 'where',
                type: {
                  kind: 'SCALAR',
                  name: 'Any',
                },
              },
            ],
          },
          {
            name: 'id',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'kommune',
            type: {
              kind: 'OBJECT',
              name: 'BereichBKommune',
              ofType: null,
            },
            args: [
              {
                name: 'fallbackLocale',
                type: {
                  kind: 'SCALAR',
                  name: 'Any',
                },
              },
              {
                name: 'locale',
                type: {
                  kind: 'SCALAR',
                  name: 'Any',
                },
              },
            ],
          },
          {
            name: 'letzteMessungZeitpunkt',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'name',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'position',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'LIST',
                ofType: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'SCALAR',
                    name: 'Float',
                    ofType: null,
                  },
                },
              },
            },
            args: [],
          },
          {
            name: 'positionCorrected',
            type: {
              kind: 'SCALAR',
              name: 'Boolean',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'shownInApp',
            type: {
              kind: 'SCALAR',
              name: 'Boolean',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'titel',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'updatedAt',
            type: {
              kind: 'SCALAR',
              name: 'DateTime',
              ofType: null,
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'OBJECT',
        name: 'Sensoren_Felder',
        fields: [
          {
            name: 'currentValue',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'hasHistorie',
            type: {
              kind: 'SCALAR',
              name: 'Boolean',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'historie',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'LIST',
                ofType: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'OBJECT',
                    name: 'SensorHistorieEintrag',
                    ofType: null,
                  },
                },
              },
            },
            args: [
              {
                name: 'duration',
                type: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'SCALAR',
                    name: 'Any',
                  },
                },
              },
            ],
          },
          {
            name: 'id',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'name',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'sensorname',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'werteart',
            type: {
              kind: 'OBJECT',
              name: 'SensorArt',
              ofType: null,
            },
            args: [
              {
                name: 'fallbackLocale',
                type: {
                  kind: 'SCALAR',
                  name: 'Any',
                },
              },
              {
                name: 'locale',
                type: {
                  kind: 'SCALAR',
                  name: 'Any',
                },
              },
            ],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'OBJECT',
        name: 'Sensorens',
        fields: [
          {
            name: 'docs',
            type: {
              kind: 'LIST',
              ofType: {
                kind: 'OBJECT',
                name: 'Sensoren',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'hasNextPage',
            type: {
              kind: 'SCALAR',
              name: 'Boolean',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'hasPrevPage',
            type: {
              kind: 'SCALAR',
              name: 'Boolean',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'limit',
            type: {
              kind: 'SCALAR',
              name: 'Int',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'nextPage',
            type: {
              kind: 'SCALAR',
              name: 'Int',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'offset',
            type: {
              kind: 'SCALAR',
              name: 'Int',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'page',
            type: {
              kind: 'SCALAR',
              name: 'Int',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'pagingCounter',
            type: {
              kind: 'SCALAR',
              name: 'Int',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'prevPage',
            type: {
              kind: 'SCALAR',
              name: 'Int',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'totalDocs',
            type: {
              kind: 'SCALAR',
              name: 'Int',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'totalPages',
            type: {
              kind: 'SCALAR',
              name: 'Int',
              ofType: null,
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'OBJECT',
        name: 'ServicePortalLink',
        fields: [
          {
            name: 'group',
            type: {
              kind: 'OBJECT',
              name: 'ProzessGroup',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'groupId',
            type: {
              kind: 'SCALAR',
              name: 'UUID',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'id',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'UUID',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'kommune',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'OBJECT',
                name: 'Kommune',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'kommuneId',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'UUID',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'name',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'url',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null,
              },
            },
            args: [],
          },
        ],
        interfaces: [
          {
            kind: 'INTERFACE',
            name: 'UserFacingProzess',
          },
        ],
      },
      {
        kind: 'OBJECT',
        name: 'ServicePortalLinkUpsertPayload',
        fields: [
          {
            name: 'servicePortalLink',
            type: {
              kind: 'OBJECT',
              name: 'ServicePortalLink',
              ofType: null,
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'OBJECT',
        name: 'SetGroupToProzessPayload',
        fields: [
          {
            name: 'userFacingProzess',
            type: {
              kind: 'INTERFACE',
              name: 'UserFacingProzess',
              ofType: null,
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'OBJECT',
        name: 'SignatureFormularFeld',
        fields: [
          {
            name: 'angezeigterName',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'anweisung',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'encrypted',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'Boolean',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'id',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'UUID',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'inhaltsTag',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'istRelevant',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'BoolExpression',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'order',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'Int',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'schritt',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'OBJECT',
                name: 'FormularSchritt',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'schrittId',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'UUID',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'slotName',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'slotValueType',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'Any',
              },
            },
            args: [],
          },
        ],
        interfaces: [
          {
            kind: 'INTERFACE',
            name: 'FormularFeld',
          },
        ],
      },
      {
        kind: 'OBJECT',
        name: 'Slot',
        fields: [
          {
            name: 'encrypted',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'Boolean',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'name',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'optional',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'Boolean',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'valueType',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'Any',
              },
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'INTERFACE',
        name: 'SlotValue',
        fields: [
          {
            name: 'bearbeitung',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'OBJECT',
                name: 'Bearbeitung',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'id',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'UUID',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'slotName',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null,
              },
            },
            args: [],
          },
        ],
        interfaces: [],
        possibleTypes: [
          {
            kind: 'OBJECT',
            name: 'EncryptedSlotValue',
          },
          {
            kind: 'OBJECT',
            name: 'PlainSlotValue',
          },
        ],
      },
      {
        kind: 'OBJECT',
        name: 'Standort',
        fields: [
          {
            name: 'id',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'nr',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'zusatz',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null,
              },
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'OBJECT',
        name: 'Strasse',
        fields: [
          {
            name: 'id',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'name',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null,
              },
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'SCALAR',
        name: 'String',
      },
      {
        kind: 'SCALAR',
        name: 'StringExpression',
      },
      {
        kind: 'OBJECT',
        name: 'StringFormularFeld',
        fields: [
          {
            name: 'angezeigterName',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'anweisung',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'encrypted',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'Boolean',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'id',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'UUID',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'inhaltsTag',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'istRelevant',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'BoolExpression',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'multiline',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'Boolean',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'options',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'LIST',
                ofType: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'OBJECT',
                    name: 'StringFormularFeldOption',
                    ofType: null,
                  },
                },
              },
            },
            args: [],
          },
          {
            name: 'order',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'Int',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'schritt',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'OBJECT',
                name: 'FormularSchritt',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'schrittId',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'UUID',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'slotName',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'slotValueType',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'Any',
              },
            },
            args: [],
          },
          {
            name: 'validationType',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'Any',
              },
            },
            args: [],
          },
        ],
        interfaces: [
          {
            kind: 'INTERFACE',
            name: 'FormularFeld',
          },
        ],
      },
      {
        kind: 'OBJECT',
        name: 'StringFormularFeldOption',
        fields: [
          {
            name: 'key',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'value',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null,
              },
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'OBJECT',
        name: 'SystemUser',
        fields: [
          {
            name: 'ausfuehrungen',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'LIST',
                ofType: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'OBJECT',
                    name: 'Ausfuehrung',
                    ofType: null,
                  },
                },
              },
            },
            args: [],
          },
          {
            name: 'id',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'UUID',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'maengel',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'LIST',
                ofType: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'OBJECT',
                    name: 'Ausfuehrung',
                    ofType: null,
                  },
                },
              },
            },
            args: [],
          },
          {
            name: 'personalPrivateKey',
            type: {
              kind: 'OBJECT',
              name: 'EncryptedValue',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'personalPrivateKeySalt',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'publicKey',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
        ],
        interfaces: [
          {
            kind: 'INTERFACE',
            name: 'User',
          },
        ],
      },
      {
        kind: 'OBJECT',
        name: 'Termin',
        fields: [
          {
            name: 'bemerkung',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'datum',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'fraktion',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'fraktionId',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'turnus',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'verschoben',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'OBJECT',
        name: 'TimeFormularFeld',
        fields: [
          {
            name: 'angezeigterName',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'anweisung',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'encrypted',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'Boolean',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'id',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'UUID',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'inhaltsTag',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'istRelevant',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'BoolExpression',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'order',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'Int',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'schritt',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'OBJECT',
                name: 'FormularSchritt',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'schrittId',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'UUID',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'slotName',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'slotValueType',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'Any',
              },
            },
            args: [],
          },
        ],
        interfaces: [
          {
            kind: 'INTERFACE',
            name: 'FormularFeld',
          },
        ],
      },
      {
        kind: 'SCALAR',
        name: 'UUID',
      },
      {
        kind: 'OBJECT',
        name: 'Untereinheit',
        fields: [
          {
            name: 'id',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'UUID',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'isGemeinde',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'Boolean',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'name',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'regionalschluessel',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'OBJECT',
        name: 'UntereinheitAssignedToUser',
        fields: [
          {
            name: 'message',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null,
              },
            },
            args: [],
          },
        ],
        interfaces: [
          {
            kind: 'INTERFACE',
            name: 'Error',
          },
        ],
      },
      {
        kind: 'OBJECT',
        name: 'UntereinheitCreatePayload',
        fields: [
          {
            name: 'untereinheit',
            type: {
              kind: 'OBJECT',
              name: 'Untereinheit',
              ofType: null,
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'UNION',
        name: 'UntereinheitDeleteError',
        possibleTypes: [
          {
            kind: 'OBJECT',
            name: 'UntereinheitAssignedToUser',
          },
          {
            kind: 'OBJECT',
            name: 'UntereinheitIsGemeindeError',
          },
          {
            kind: 'OBJECT',
            name: 'UntereinheitNotFoundError',
          },
        ],
      },
      {
        kind: 'OBJECT',
        name: 'UntereinheitDeletePayload',
        fields: [
          {
            name: 'errors',
            type: {
              kind: 'LIST',
              ofType: {
                kind: 'NON_NULL',
                ofType: {
                  kind: 'UNION',
                  name: 'UntereinheitDeleteError',
                  ofType: null,
                },
              },
            },
            args: [],
          },
          {
            name: 'untereinheit',
            type: {
              kind: 'LIST',
              ofType: {
                kind: 'NON_NULL',
                ofType: {
                  kind: 'OBJECT',
                  name: 'Untereinheit',
                  ofType: null,
                },
              },
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'OBJECT',
        name: 'UntereinheitIsGemeindeError',
        fields: [
          {
            name: 'message',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null,
              },
            },
            args: [],
          },
        ],
        interfaces: [
          {
            kind: 'INTERFACE',
            name: 'Error',
          },
        ],
      },
      {
        kind: 'OBJECT',
        name: 'UntereinheitNotFoundError',
        fields: [
          {
            name: 'message',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null,
              },
            },
            args: [],
          },
        ],
        interfaces: [
          {
            kind: 'INTERFACE',
            name: 'Error',
          },
        ],
      },
      {
        kind: 'OBJECT',
        name: 'UntereinheitPermissions',
        fields: [
          {
            name: 'backofficeUserId',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'UUID',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'canEditNews',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'Boolean',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'canEditVeranstaltungen',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'Boolean',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'untereinheit',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'OBJECT',
                name: 'Untereinheit',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'untereinheitId',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'UUID',
                ofType: null,
              },
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'UNION',
        name: 'UntereinheitUpdateError',
        possibleTypes: [
          {
            kind: 'OBJECT',
            name: 'UntereinheitIsGemeindeError',
          },
          {
            kind: 'OBJECT',
            name: 'UntereinheitNotFoundError',
          },
        ],
      },
      {
        kind: 'OBJECT',
        name: 'UntereinheitUpdatePayload',
        fields: [
          {
            name: 'errors',
            type: {
              kind: 'LIST',
              ofType: {
                kind: 'NON_NULL',
                ofType: {
                  kind: 'UNION',
                  name: 'UntereinheitUpdateError',
                  ofType: null,
                },
              },
            },
            args: [],
          },
          {
            name: 'untereinheit',
            type: {
              kind: 'OBJECT',
              name: 'Untereinheit',
              ofType: null,
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'OBJECT',
        name: 'UpdateProzessGroupPayload',
        fields: [
          {
            name: 'prozessGroup',
            type: {
              kind: 'OBJECT',
              name: 'ProzessGroup',
              ofType: null,
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'UNION',
        name: 'UpdateUserGroupError',
        possibleTypes: [
          {
            kind: 'OBJECT',
            name: 'GroupNameInvalid',
          },
        ],
      },
      {
        kind: 'OBJECT',
        name: 'UpdateUserGroupPayload',
        fields: [
          {
            name: 'errors',
            type: {
              kind: 'LIST',
              ofType: {
                kind: 'NON_NULL',
                ofType: {
                  kind: 'UNION',
                  name: 'UpdateUserGroupError',
                  ofType: null,
                },
              },
            },
            args: [],
          },
          {
            name: 'mitarbeiterGruppe',
            type: {
              kind: 'OBJECT',
              name: 'MitarbeiterGruppe',
              ofType: null,
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'OBJECT',
        name: 'UploadFormularFeld',
        fields: [
          {
            name: 'angezeigterName',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'anweisung',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'document',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'Boolean',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'encrypted',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'Boolean',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'id',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'UUID',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'inhaltsTag',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'istRelevant',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'BoolExpression',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'multi',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'Boolean',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'order',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'Int',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'schritt',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'OBJECT',
                name: 'FormularSchritt',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'schrittId',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'UUID',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'slotName',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'slotValueType',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'Any',
              },
            },
            args: [],
          },
        ],
        interfaces: [
          {
            kind: 'INTERFACE',
            name: 'FormularFeld',
          },
        ],
      },
      {
        kind: 'INTERFACE',
        name: 'User',
        fields: [
          {
            name: 'id',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'UUID',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'publicKey',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
        ],
        interfaces: [],
        possibleTypes: [
          {
            kind: 'OBJECT',
            name: 'BackofficeUser',
          },
          {
            kind: 'OBJECT',
            name: 'Buerger',
          },
          {
            kind: 'OBJECT',
            name: 'SystemUser',
          },
        ],
      },
      {
        kind: 'INTERFACE',
        name: 'UserFacingProzess',
        fields: [
          {
            name: 'group',
            type: {
              kind: 'OBJECT',
              name: 'ProzessGroup',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'groupId',
            type: {
              kind: 'SCALAR',
              name: 'UUID',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'id',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'UUID',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'kommune',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'OBJECT',
                name: 'Kommune',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'kommuneId',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'UUID',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'name',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null,
              },
            },
            args: [],
          },
        ],
        interfaces: [],
        possibleTypes: [
          {
            kind: 'OBJECT',
            name: 'EfaLeistung',
          },
          {
            kind: 'OBJECT',
            name: 'Prozess',
          },
          {
            kind: 'OBJECT',
            name: 'ServicePortalLink',
          },
        ],
      },
      {
        kind: 'OBJECT',
        name: 'Veranstaltungskalender',
        fields: [
          {
            name: 'category',
            type: {
              kind: 'LIST',
              ofType: {
                kind: 'NON_NULL',
                ofType: {
                  kind: 'OBJECT',
                  name: 'VeranstaltungskalenderKategorie',
                  ofType: null,
                },
              },
            },
            args: [
              {
                name: 'fallbackLocale',
                type: {
                  kind: 'SCALAR',
                  name: 'Any',
                },
              },
              {
                name: 'locale',
                type: {
                  kind: 'SCALAR',
                  name: 'Any',
                },
              },
            ],
          },
          {
            name: 'createdAt',
            type: {
              kind: 'SCALAR',
              name: 'DateTime',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'id',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'inhalte',
            type: {
              kind: 'LIST',
              ofType: {
                kind: 'NON_NULL',
                ofType: {
                  kind: 'UNION',
                  name: 'Veranstaltungskalender_Inhalte',
                  ofType: null,
                },
              },
            },
            args: [],
          },
          {
            name: 'kommune',
            type: {
              kind: 'OBJECT',
              name: 'BereichBKommune',
              ofType: null,
            },
            args: [
              {
                name: 'fallbackLocale',
                type: {
                  kind: 'SCALAR',
                  name: 'Any',
                },
              },
              {
                name: 'locale',
                type: {
                  kind: 'SCALAR',
                  name: 'Any',
                },
              },
            ],
          },
          {
            name: 'link',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'lockedForImport',
            type: {
              kind: 'SCALAR',
              name: 'Boolean',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'source',
            type: {
              kind: 'LIST',
              ofType: {
                kind: 'NON_NULL',
                ofType: {
                  kind: 'OBJECT',
                  name: 'VeranstaltungskalenderQuelle',
                  ofType: null,
                },
              },
            },
            args: [
              {
                name: 'fallbackLocale',
                type: {
                  kind: 'SCALAR',
                  name: 'Any',
                },
              },
              {
                name: 'locale',
                type: {
                  kind: 'SCALAR',
                  name: 'Any',
                },
              },
            ],
          },
          {
            name: 'subtitle',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'teaser',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'timeAndLocation',
            type: {
              kind: 'OBJECT',
              name: 'Veranstaltungskalender_TimeAndLocation',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'titel',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'titelbild',
            type: {
              kind: 'OBJECT',
              name: 'Veranstaltungskalender_Titelbild',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'untereinheit',
            type: {
              kind: 'OBJECT',
              name: 'BereichBUntereinheit',
              ofType: null,
            },
            args: [
              {
                name: 'fallbackLocale',
                type: {
                  kind: 'SCALAR',
                  name: 'Any',
                },
              },
              {
                name: 'locale',
                type: {
                  kind: 'SCALAR',
                  name: 'Any',
                },
              },
            ],
          },
          {
            name: 'updatedAt',
            type: {
              kind: 'SCALAR',
              name: 'DateTime',
              ofType: null,
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'OBJECT',
        name: 'VeranstaltungskalenderKategorie',
        fields: [
          {
            name: 'createdAt',
            type: {
              kind: 'SCALAR',
              name: 'DateTime',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'filterkategorie',
            type: {
              kind: 'SCALAR',
              name: 'Boolean',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'id',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'kommune',
            type: {
              kind: 'OBJECT',
              name: 'BereichBKommune',
              ofType: null,
            },
            args: [
              {
                name: 'fallbackLocale',
                type: {
                  kind: 'SCALAR',
                  name: 'Any',
                },
              },
              {
                name: 'locale',
                type: {
                  kind: 'SCALAR',
                  name: 'Any',
                },
              },
            ],
          },
          {
            name: 'label',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'updatedAt',
            type: {
              kind: 'SCALAR',
              name: 'DateTime',
              ofType: null,
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'OBJECT',
        name: 'VeranstaltungskalenderKategories',
        fields: [
          {
            name: 'docs',
            type: {
              kind: 'LIST',
              ofType: {
                kind: 'OBJECT',
                name: 'VeranstaltungskalenderKategorie',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'hasNextPage',
            type: {
              kind: 'SCALAR',
              name: 'Boolean',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'hasPrevPage',
            type: {
              kind: 'SCALAR',
              name: 'Boolean',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'limit',
            type: {
              kind: 'SCALAR',
              name: 'Int',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'nextPage',
            type: {
              kind: 'SCALAR',
              name: 'Int',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'offset',
            type: {
              kind: 'SCALAR',
              name: 'Int',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'page',
            type: {
              kind: 'SCALAR',
              name: 'Int',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'pagingCounter',
            type: {
              kind: 'SCALAR',
              name: 'Int',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'prevPage',
            type: {
              kind: 'SCALAR',
              name: 'Int',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'totalDocs',
            type: {
              kind: 'SCALAR',
              name: 'Int',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'totalPages',
            type: {
              kind: 'SCALAR',
              name: 'Int',
              ofType: null,
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'OBJECT',
        name: 'VeranstaltungskalenderQuelle',
        fields: [
          {
            name: 'createdAt',
            type: {
              kind: 'SCALAR',
              name: 'DateTime',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'id',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'kommune',
            type: {
              kind: 'OBJECT',
              name: 'BereichBKommune',
              ofType: null,
            },
            args: [
              {
                name: 'fallbackLocale',
                type: {
                  kind: 'SCALAR',
                  name: 'Any',
                },
              },
              {
                name: 'locale',
                type: {
                  kind: 'SCALAR',
                  name: 'Any',
                },
              },
            ],
          },
          {
            name: 'logo',
            type: {
              kind: 'OBJECT',
              name: 'VeranstaltungskalenderQuelle_Logo',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'name',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'updatedAt',
            type: {
              kind: 'SCALAR',
              name: 'DateTime',
              ofType: null,
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'OBJECT',
        name: 'VeranstaltungskalenderQuelle_Logo',
        fields: [
          {
            name: 'external',
            type: {
              kind: 'SCALAR',
              name: 'Boolean',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'internal',
            type: {
              kind: 'OBJECT',
              name: 'Veranstaltungsmedien',
              ofType: null,
            },
            args: [
              {
                name: 'fallbackLocale',
                type: {
                  kind: 'SCALAR',
                  name: 'Any',
                },
              },
              {
                name: 'locale',
                type: {
                  kind: 'SCALAR',
                  name: 'Any',
                },
              },
              {
                name: 'where',
                type: {
                  kind: 'SCALAR',
                  name: 'Any',
                },
              },
            ],
          },
          {
            name: 'url',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'OBJECT',
        name: 'VeranstaltungskalenderQuellen',
        fields: [
          {
            name: 'docs',
            type: {
              kind: 'LIST',
              ofType: {
                kind: 'OBJECT',
                name: 'VeranstaltungskalenderQuelle',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'hasNextPage',
            type: {
              kind: 'SCALAR',
              name: 'Boolean',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'hasPrevPage',
            type: {
              kind: 'SCALAR',
              name: 'Boolean',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'limit',
            type: {
              kind: 'SCALAR',
              name: 'Int',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'nextPage',
            type: {
              kind: 'SCALAR',
              name: 'Int',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'offset',
            type: {
              kind: 'SCALAR',
              name: 'Int',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'page',
            type: {
              kind: 'SCALAR',
              name: 'Int',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'pagingCounter',
            type: {
              kind: 'SCALAR',
              name: 'Int',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'prevPage',
            type: {
              kind: 'SCALAR',
              name: 'Int',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'totalDocs',
            type: {
              kind: 'SCALAR',
              name: 'Int',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'totalPages',
            type: {
              kind: 'SCALAR',
              name: 'Int',
              ofType: null,
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'UNION',
        name: 'Veranstaltungskalender_Inhalte',
        possibleTypes: [
          {
            kind: 'OBJECT',
            name: 'ImageBlockVeranstaltungsmedien',
          },
          {
            kind: 'OBJECT',
            name: 'RichTextBlock',
          },
        ],
      },
      {
        kind: 'OBJECT',
        name: 'Veranstaltungskalender_TimeAndLocation',
        fields: [
          {
            name: 'address',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'coordinates',
            type: {
              kind: 'LIST',
              ofType: {
                kind: 'NON_NULL',
                ofType: {
                  kind: 'SCALAR',
                  name: 'Float',
                  ofType: null,
                },
              },
            },
            args: [],
          },
          {
            name: 'dates',
            type: {
              kind: 'LIST',
              ofType: {
                kind: 'NON_NULL',
                ofType: {
                  kind: 'OBJECT',
                  name: 'Veranstaltungskalender_TimeAndLocation_Dates',
                  ofType: null,
                },
              },
            },
            args: [],
          },
          {
            name: 'locationName',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'OBJECT',
        name: 'Veranstaltungskalender_TimeAndLocation_Dates',
        fields: [
          {
            name: 'end',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'DateTime',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'id',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'start',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'DateTime',
                ofType: null,
              },
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'OBJECT',
        name: 'Veranstaltungskalender_Titelbild',
        fields: [
          {
            name: 'external',
            type: {
              kind: 'SCALAR',
              name: 'Boolean',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'internal',
            type: {
              kind: 'OBJECT',
              name: 'Veranstaltungsmedien',
              ofType: null,
            },
            args: [
              {
                name: 'fallbackLocale',
                type: {
                  kind: 'SCALAR',
                  name: 'Any',
                },
              },
              {
                name: 'locale',
                type: {
                  kind: 'SCALAR',
                  name: 'Any',
                },
              },
              {
                name: 'where',
                type: {
                  kind: 'SCALAR',
                  name: 'Any',
                },
              },
            ],
          },
          {
            name: 'url',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'OBJECT',
        name: 'Veranstaltungskalenders',
        fields: [
          {
            name: 'docs',
            type: {
              kind: 'LIST',
              ofType: {
                kind: 'OBJECT',
                name: 'Veranstaltungskalender',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'hasNextPage',
            type: {
              kind: 'SCALAR',
              name: 'Boolean',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'hasPrevPage',
            type: {
              kind: 'SCALAR',
              name: 'Boolean',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'limit',
            type: {
              kind: 'SCALAR',
              name: 'Int',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'nextPage',
            type: {
              kind: 'SCALAR',
              name: 'Int',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'offset',
            type: {
              kind: 'SCALAR',
              name: 'Int',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'page',
            type: {
              kind: 'SCALAR',
              name: 'Int',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'pagingCounter',
            type: {
              kind: 'SCALAR',
              name: 'Int',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'prevPage',
            type: {
              kind: 'SCALAR',
              name: 'Int',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'totalDocs',
            type: {
              kind: 'SCALAR',
              name: 'Int',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'totalPages',
            type: {
              kind: 'SCALAR',
              name: 'Int',
              ofType: null,
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'OBJECT',
        name: 'Veranstaltungsmedien',
        fields: [
          {
            name: 'createdAt',
            type: {
              kind: 'SCALAR',
              name: 'DateTime',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'filename',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'filesize',
            type: {
              kind: 'SCALAR',
              name: 'Float',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'height',
            type: {
              kind: 'SCALAR',
              name: 'Float',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'id',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'kommune',
            type: {
              kind: 'OBJECT',
              name: 'BereichBKommune',
              ofType: null,
            },
            args: [
              {
                name: 'fallbackLocale',
                type: {
                  kind: 'SCALAR',
                  name: 'Any',
                },
              },
              {
                name: 'locale',
                type: {
                  kind: 'SCALAR',
                  name: 'Any',
                },
              },
            ],
          },
          {
            name: 'mimeType',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'sizes',
            type: {
              kind: 'OBJECT',
              name: 'Veranstaltungsmedien_Sizes',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'sourceUrl',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'updatedAt',
            type: {
              kind: 'SCALAR',
              name: 'DateTime',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'url',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'width',
            type: {
              kind: 'SCALAR',
              name: 'Float',
              ofType: null,
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'OBJECT',
        name: 'Veranstaltungsmedien_Sizes',
        fields: [
          {
            name: 'thumbnail',
            type: {
              kind: 'OBJECT',
              name: 'Veranstaltungsmedien_Sizes_Thumbnail',
              ofType: null,
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'OBJECT',
        name: 'Veranstaltungsmedien_Sizes_Thumbnail',
        fields: [
          {
            name: 'filename',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'filesize',
            type: {
              kind: 'SCALAR',
              name: 'Float',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'height',
            type: {
              kind: 'SCALAR',
              name: 'Float',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'mimeType',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'url',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'width',
            type: {
              kind: 'SCALAR',
              name: 'Float',
              ofType: null,
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'OBJECT',
        name: 'Verbindung',
        fields: [
          {
            name: 'id',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'UUID',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'istRelevant',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'BoolExpression',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'nach',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'INTERFACE',
                name: 'Schritt',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'nachId',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'UUID',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'name',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'statusAenderung',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'statusAenderungBeschreibung',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'von',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'INTERFACE',
                name: 'Schritt',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'vonId',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'UUID',
                ofType: null,
              },
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'OBJECT',
        name: 'VergangenerSchritt',
        fields: [
          {
            name: 'bearbeitenderUser',
            type: {
              kind: 'INTERFACE',
              name: 'User',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'bearbeitungsDatum',
            type: {
              kind: 'SCALAR',
              name: 'Instant',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'schritt',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'INTERFACE',
                name: 'Schritt',
                ofType: null,
              },
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'OBJECT',
        name: 'WarteAufBezahlungAbgeschlossenSchritt',
        fields: [
          {
            name: 'anweisung',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'bezahlenSchritt',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'OBJECT',
                name: 'BezahlenSchritt',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'bezahlenSchrittId',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'UUID',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'erledigungDurchBuerger',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'Boolean',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'id',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'UUID',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'informationstext',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'mitarbeiterGruppe',
            type: {
              kind: 'OBJECT',
              name: 'MitarbeiterGruppe',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'mitarbeiterGruppeId',
            type: {
              kind: 'SCALAR',
              name: 'UUID',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'prozessVersion',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'OBJECT',
                name: 'ProzessVersion',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'prozessVersionId',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'UUID',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'slots',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'LIST',
                ofType: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'OBJECT',
                    name: 'Slot',
                    ofType: null,
                  },
                },
              },
            },
            args: [],
          },
          {
            name: 'titel',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'verbindungen',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'LIST',
                ofType: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'OBJECT',
                    name: 'Verbindung',
                    ofType: null,
                  },
                },
              },
            },
            args: [],
          },
        ],
        interfaces: [
          {
            kind: 'INTERFACE',
            name: 'Schritt',
          },
        ],
      },
      {
        kind: 'OBJECT',
        name: 'WarteAufGovManagerSchritt',
        fields: [
          {
            name: 'anweisung',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'erledigungDurchBuerger',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'Boolean',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'govManagerSchritt',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'OBJECT',
                name: 'GovManagerRestExportSchritt',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'govManagerSchrittId',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'UUID',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'id',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'UUID',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'informationstext',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'mitarbeiterGruppe',
            type: {
              kind: 'OBJECT',
              name: 'MitarbeiterGruppe',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'mitarbeiterGruppeId',
            type: {
              kind: 'SCALAR',
              name: 'UUID',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'prozessVersion',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'OBJECT',
                name: 'ProzessVersion',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'prozessVersionId',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'UUID',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'slots',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'LIST',
                ofType: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'OBJECT',
                    name: 'Slot',
                    ofType: null,
                  },
                },
              },
            },
            args: [],
          },
          {
            name: 'titel',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'verbindungen',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'LIST',
                ofType: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'OBJECT',
                    name: 'Verbindung',
                    ofType: null,
                  },
                },
              },
            },
            args: [],
          },
        ],
        interfaces: [
          {
            kind: 'INTERFACE',
            name: 'Schritt',
          },
        ],
      },
      {
        kind: 'OBJECT',
        name: 'Wettermesspunkte',
        fields: [
          {
            name: 'createdAt',
            type: {
              kind: 'SCALAR',
              name: 'DateTime',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'id',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'kommune',
            type: {
              kind: 'OBJECT',
              name: 'BereichBKommune',
              ofType: null,
            },
            args: [
              {
                name: 'fallbackLocale',
                type: {
                  kind: 'SCALAR',
                  name: 'Any',
                },
              },
              {
                name: 'locale',
                type: {
                  kind: 'SCALAR',
                  name: 'Any',
                },
              },
            ],
          },
          {
            name: 'location',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'LIST',
                ofType: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'SCALAR',
                    name: 'Float',
                    ofType: null,
                  },
                },
              },
            },
            args: [],
          },
          {
            name: 'locationName',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'updatedAt',
            type: {
              kind: 'SCALAR',
              name: 'DateTime',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'weather',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'LIST',
                ofType: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'OBJECT',
                    name: 'Wettermesspunkte_Weather',
                    ofType: null,
                  },
                },
              },
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'OBJECT',
        name: 'Wettermesspunkte_Weather',
        fields: [
          {
            name: 'date',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'DateTime',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'icon',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'id',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'maxTemp',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'Float',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'minTemp',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'Float',
                ofType: null,
              },
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'OBJECT',
        name: 'Wettermesspunktes',
        fields: [
          {
            name: 'docs',
            type: {
              kind: 'LIST',
              ofType: {
                kind: 'OBJECT',
                name: 'Wettermesspunkte',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'hasNextPage',
            type: {
              kind: 'SCALAR',
              name: 'Boolean',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'hasPrevPage',
            type: {
              kind: 'SCALAR',
              name: 'Boolean',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'limit',
            type: {
              kind: 'SCALAR',
              name: 'Int',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'nextPage',
            type: {
              kind: 'SCALAR',
              name: 'Int',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'offset',
            type: {
              kind: 'SCALAR',
              name: 'Int',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'page',
            type: {
              kind: 'SCALAR',
              name: 'Int',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'pagingCounter',
            type: {
              kind: 'SCALAR',
              name: 'Int',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'prevPage',
            type: {
              kind: 'SCALAR',
              name: 'Int',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'totalDocs',
            type: {
              kind: 'SCALAR',
              name: 'Int',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'totalPages',
            type: {
              kind: 'SCALAR',
              name: 'Int',
              ofType: null,
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'SCALAR',
        name: 'Any',
      },
    ],
    directives: [],
  },
} as unknown as IntrospectionQuery;
